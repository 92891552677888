<template>
  <div class="exercise read-exercise">
    <PicturePuzzle
      :scene-id="sceneId"
      @clicked-canvas="checkSolution"
    />
  </div>
</template>

<script setup lang="ts">
import type{ TaskPuzzle } from '~/types/game';
import PicturePuzzle from '~/components/exercises/components/PicturePuzzle.vue';

const props = defineProps<{
  task: TaskPuzzle;
  sceneId: string;
}>();

const emit = defineEmits<{(e: 'input', isRight: boolean): void }>();

function checkSolution(layerName: string, resolve: (answer: string, color: string) => void) {
  const isRight = props.task.solutions.includes(layerName);
  emit('input', isRight);
  resolve(isRight ? props.task.statement.key : '', isRight ? 'positive' : 'negative');
}
</script>

<style scoped>

</style>
