<template>
  <div
    class="exercise assign-exercise"
    :class="{
      '-freeze': selectedOption || !imagesLoaded ,
      '-hidden': isHidden,
      '-show': imagesLoaded,
    }"
  >
    <fieldset
      v-if="task"
      class="options options-container"
      :class="{
      }"
    >
      <div
        v-for="(option, i) in options"
        :key="`${images[i]}-${key}`"
        class="option assign-option"
        :class="{
          '-selected': selectedOption?.key === option.key,
          '-wrong': selectionState === 'isWrong',
          '-right': selectionState === 'isRight',

        }"
      >
        <label
          class="label"
          :for="option.key"
          :style="{
            animationDelay: `${80 + i * 50}ms`,
            transitionDelay: `${80 + i * 80}ms`
          }"
        >
          <span class="border" />
          <img
            class="image"
            :src="images[i]"
            :alt="''"
          >
          <input
            :id="option.key"
            v-model="selectedOption"
            class="input"
            type="radio"
            :name="task.key"
            :value="option"
            @change="checkSolution"
          >
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import {TaskAssign} from '~/types/game';

const props = defineProps<{
  tasks: TaskAssign[];
  task: TaskAssign;
  sceneId: string;
}>();

watch(() => props.task, () => {
  setTimeout(() => {
    hideOptions();
  }, 600);

  setTimeout(() => {
    setOptions();
  }, 900);
});

const emit = defineEmits<{(e: 'input', isRight: boolean): void }>();

const selectedOption = ref<(TaskAssign | null)>(null);
const options = ref<TaskAssign[]>([]);
const selectionState = ref<'isRight' | 'isWrong' | null>(null);
const images = ref<string[]>([]);
const imagesLoaded = ref<boolean>(false);
const key = ref<number>(0);
const isHidden = ref<boolean>(true);

setOptions();

function hideOptions() {
  isHidden.value = true;
}

async function setOptions() {
  const newOptions = getNewRandomOptions();
  const newImages = newOptions.map((o) => `/scenes/${props.sceneId}/images/${o.image}`);
  imagesLoaded.value = false;

  await loadImages(images.value);

  setTimeout(() => {
    options.value = newOptions.slice(0);
    isHidden.value = false;
    imagesLoaded.value = true;
  }, 1500);

  setTimeout(() => {
    selectedOption.value = null;
    selectionState.value = null;
    images.value = newImages.slice(0);
    key.value += 1;
  }, 800);
}

function checkSolution() {
  if (selectedOption.value?.key === props.task.key) {
    emit('input', true);
    selectionState.value = 'isRight';
  } else {
    emit('input', false);
    selectionState.value = 'isWrong';
  }
}

function getMultipleRandom(arr: any[], num: number) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}

function loadImages(images: string[]) {
  return Promise.all(images.map((i) => preloadImage(i)));
}

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;
    image.src = src;
  });
}

function getNewRandomOptions() {
  const newOp = getMultipleRandom(props.tasks, 6);
  if (!newOp.find((item: TaskAssign) => item.key === props.task.key)) {
    const randomIndex = Math.floor(Math.random() * 5);
    newOp[randomIndex] = props.task;
  }
  return newOp;
}

</script>

<style scoped>

.assign-exercise {
  overflow-y: hidden;
  display: block;

  &.-freeze {
    pointer-events: none;
  }

  &.-hidden {
    pointer-events: none;

    > .options {
      > .option {
        > .label {
          transform: scale(0);
        }
      }
    }
  }

  &.-show {

    > .options {
      > .option {
        > .label {
          transform: scale(1);
        }
      }
    }
  }

}

.options-container {
  @mixin container-layout-suchbuch;
  @mixin grid-base;

  border: none;
  grid-gap: 0;
  padding-top: 6%;
  padding-bottom: 0;

  @media (--sm) {
    --grid-columns: 2;
    --size: min(calc(calc(var(--app-height) - 15rem) * 0.3), 40vw);
  }

  @media (--md) {
    --grid-columns: 2;
    --size: min(40vw, calc(var(--app-height) * 0.25));
  }

  @media (--lg-up) {
    --grid-columns: 3;
    --size: min(27vw, calc(var(--app-height) * 0.32));
  }
}

.assign-option {
  --feedback-color: transparent;
  --feedback-image: none;

  user-select: none;

  &.-selected {

    > .label {
      > .image {
        transform: scale(1.01);
      }

      > .border {
        animation: pop-in 500ms linear;
        animation-fill-mode: forwards;
      }

      &::after,
      &::before {
        animation: fade-in 450ms ease-in-out var(--delay);
        animation-fill-mode: forwards;
      }
    }
  }

  &.-hidden {

  }

  &.-show {

  }

  &.-wrong.-selected {
    --feedback-color: var(--color-tuscany-300);
    --feedback-image: url('/icons/icon-badge-wrong.svg');
  }

  &.-right.-selected {
    --feedback-color: var(--color-fruitsalad-300);
    --feedback-image: url('/icons/icon-badge-right.svg');
  }

  > .label {
    width: var(--size);
    height: var(--size);
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation-delay: inherit;
    transition: transform 350ms;

    &:hover {
      > .image {
        transform: scale(1.01);
      }
    }

    &::after,
    &::before,
    > .border,
    > .image {
      width: calc(0.9 * var(--size));
      height: calc(0.9 * var(--size));
      border-radius: 50%;
      transform-origin: center center;
    }

    &::before,
    &::after {
      content: "";
      inset: 5%;
      transform: scale(0);
      position: absolute;
    }

    &::before {
      --delay: 550ms;

      background-color: var(--feedback-color);
      z-index: 1;
    }

    &::after {
      --delay: 900ms;

      background-image: var(--feedback-image);
      background-position: center center;
      background-size: 25% 25%;
      background-repeat: no-repeat;
      z-index: 2;
    }

    > .border {
      position: absolute;
      inset: 5%;
      border-radius: 50%;
      transform: scale(0);
      border: 0.8rem solid var(--feedback-color);
    }

    > .image {
      padding: 0;
      transform: scale(0.95);
      transition: transform var(--base-transition-duration);
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    > .input {
      display: none;
    }
  }
}

@keyframes fade-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes pop-in {
  0% {
    transform: scale(0.8);
  }

  80% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1.1);
  }
}

</style>
