<template>
  <div
    v-if="!isCanvasInitialised"
    class="placeholder-container"
    :class="{ '-hidden': isCanvasInitialised}"
  >
    <img
      class="image"
      :src="`/scenes/${props.sceneId}/photoshop.webp`"
      :alt="props.sceneId"
    >
  </div>
  <div
    ref="panZoomWrapper"
    class="panzoom-container"
  >
    <div
      ref="mainContainer"
      class="panzoom main-container"
      :class="{'-loading': !isPsdLoaded}"
    >
      <div
        v-show="isCanvasInitialised"
        ref="canvasContainer"
        class="main canvas-container"
      >
        <span
          :ref="el => feedbackMarker.element = el"
          class="feedback-marker"
          :class="[`-${feedbackMarker.status || 'hidden'}`]"
        >
          <span class="label">
            <span class="icon" />
            {{ feedbackMarker.label }}
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="top-container">
    <div
      class="button-container container"
      @click="centerPanzoom"
    >
      <ButtonIcon
        class="button"
        :label="t('buttons.center')"
        icon="compass"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  onBeforeUnmount, onMounted, ref, watch, watchEffect,
} from 'vue';
import createPanZoom from 'panzoom';
import type { PanZoom } from 'panzoom';
import { useI18n } from 'vue-i18n';
import { Layer } from 'ag-psd';
import { CanvasHotspot, FeedbackMarker } from '~/types/game';
import { usePsd } from '~/data/usePsd';
import ButtonIcon from '~/components/utils/ButtonIcon.vue';
import { injectStrict } from '~/utils/inject';
import { AudioStreamInjection } from '~/data/useAudio';
import router from '~/router';

// types
type Status = 'positive' | 'negative' | 'neutral'

// variables
let panZoomInstance: PanZoom | null = null;
const feedbackColors: Record<Status, string> = {
  neutral: '#FAFF04',
  positive: '#04FF1D',
  negative: '#FF0404',
};

const { stopAudioQueue } = injectStrict(AudioStreamInjection);

// props
const props = defineProps<{
  sceneId: string;
  isNoise?: boolean;
}>();

// emit
const emit = defineEmits<{(e: 'clickedCanvas', layerName: string, callback: (answer: string, status: Status) => void): void;
  (e: 'initialised'): void;
}>();

// refs
const { t } = useI18n();
const isCanvasInitialised = ref<boolean>(false);
const mainContainer = ref<HTMLDivElement | null>(null);
const canvasContainer = ref<HTMLDivElement | null>(null);
const panZoomWrapper = ref<HTMLDivElement | null>(null);
const activeHotspot = ref<{ item: null | CanvasHotspot; status: Status; label: string; timeout: null | ReturnType<typeof setTimeout> }>({
  item: null,
  status: 'neutral',
  label: '',
  timeout: null,
});

const feedbackMarker = ref<FeedbackMarker>({
  element: null,
  status: null,
  label: '',
});

const {
  loadPsd, backgroundLayer, hotspots, isLoaded: isPsdLoaded, hasLoadingFailed,
} = usePsd(props.sceneId, props.isNoise || false);

// watcher
watch(isPsdLoaded, () => {
  console.log('[psd loading status change]', 'loaded: ', isPsdLoaded.value)

  if (isPsdLoaded.value) {
    drawBackground();
    drawHotspots();
    resizeCanvas();
    createPanZoomInstance();
    centerPanzoom();
    emit('initialised');
    isCanvasInitialised.value = true;
    console.log('Canvas initialised')
  }
});

watchEffect(() => {
  if (hasLoadingFailed.value) {
    router.replace({ name: 'Suchbuch-Overview' });
  }
});

// methods
function drawBackground() {
  const canvas = backgroundLayer.value?.canvas;
  if (backgroundLayer.value && canvas) {
    canvas.style.top = `${backgroundLayer.value.top}px`;
    canvas.style.left = `${backgroundLayer.value.left}px`;
    canvasContainer.value?.appendChild(canvas);
  }
}

function drawHotspots() {
  hotspots.value.forEach((item) => {
    initHotspotCanvas(item, 5);
    canvasContainer.value?.appendChild(item.canvas);
  });
}

function initHotspotCanvas(hotspot: CanvasHotspot, padding: number) {
  if (!hotspot || !hotspot.ctx || !hotspot.canvas) {
    return;
  }
  hotspot.image.src = hotspot.imageSrc;
  if (hotspot.id === 'blocker') hotspot.canvas.setAttribute('data-blocker', 'true');
  else hotspot.canvas.setAttribute('data-interactive', 'true');
  hotspot.canvas.setAttribute('data-hotspot-id', hotspot.id);
  hotspot.canvas.style.top = `${hotspot.layer.top}px`;
  hotspot.canvas.style.left = `${hotspot.layer.left}px`;
  hotspot.canvas.width += padding * 2;
  hotspot.canvas.height += padding * 2;
  hotspot.ctx.translate(padding, padding);
  hotspot.canvas.style.transform = `translate(${padding * -1}px, ${padding * -1}px)`;
}

function clearHotspot(hotspot: CanvasHotspot, padding: number) {
  if (!hotspot.ctx || !hotspot.canvas) {
    return;
  }
  hotspot.ctx.clearRect(padding * -1, padding * -1, hotspot.canvas.width + padding, hotspot.canvas.height + padding);
  hotspot.canvas.style.transform = `translate(${padding * -1}px, ${padding * -1}px)`;
}

function redrawHotspots() {
  hotspots.value.forEach((item) => {
    clearHotspot(item, 5);
  });
}

function setFeedbackMarker(layer: Layer, content: string, status: Status) {
  setFeedbackMarkerText(content);
  setFeedbackMarkerStatus(status);
  setFeedbackMarkerPosition(layer);
}

function setFeedbackMarkerStatus(status: Status | null) {
  feedbackMarker.value.status = status;
}

function setFeedbackMarkerText(text: string) {
  feedbackMarker.value.label = text.length ? t(`keys.${text}`) : '';
}

function setFeedbackMarkerPosition(layer: Layer) {
  if (!feedbackMarker.value.element || !backgroundLayer.value) return;
  const top = layer.top || 0;
  const bottom = layer.bottom || 0;
  const left = layer.left || 0;
  const right = layer.right || 0;

  let offsetTop = 0;
  const height = bottom - top;

  if (height <= 100) {
    offsetTop = 40;
  } else if (height <= 150) {
    offsetTop = 30;
  }

  const styleTop = Math.min((backgroundLayer.value.bottom as number) - 100, bottom) + offsetTop;
  const styleLeft = right - ((right - left) * 0.5);

  feedbackMarker.value.element.style.top = `${styleTop}px`;
  feedbackMarker.value.element.style.left = `${styleLeft}px`;

  feedbackMarker.value.element.setAttribute('label-positionx', '');

  if (styleLeft >= 2100) {
    feedbackMarker.value.element.setAttribute('label-positionx', 'left');
  } else if (styleLeft <= 100) {
    feedbackMarker.value.element.setAttribute('label-positionx', 'right');
  }
}

function changeHotspot(item: CanvasHotspot | null, status: Status = 'neutral', label = '') {
  const oldActiveHotspotItem = activeHotspot.value.item;
  activeHotspot.value.item = item;
  activeHotspot.value.status = status;
  activeHotspot.value.label = label;

  if (oldActiveHotspotItem) {
    clearHotspot(oldActiveHotspotItem, 5);
    setFeedbackMarkerStatus(null);
  }

  if (activeHotspot.value.item) {
    if (activeHotspot.value.timeout) clearTimeout(activeHotspot.value.timeout);

    activeHotspot.value.timeout = setTimeout(() => {
      clearHotspot(activeHotspot.value.item as CanvasHotspot, 5);
      setFeedbackMarkerStatus(null);
    }, 3000);

    drawHotspot(activeHotspot.value.item, feedbackColors[activeHotspot.value.status]);
    setFeedbackMarker(activeHotspot.value.item.layer, activeHotspot.value.label, activeHotspot.value.status);
  }
}

function drawHotspot(hotspot: CanvasHotspot, color: string) {
  drawHotspotBorder(hotspot, 5, color);
  drawHotspotImage(hotspot);
}

function drawHotspotBorder(hotspot: CanvasHotspot, padding: number, color: string) {
  const offsetArray = [
    -1, -1, 0, -1,
    1, -1, -1, 0,
    1, 0, -1, 1,
    0, 1, 1, 1,
  ];
  const thicknessScale = 4;
  const x = 0;
  const y = 0;

  // draw images at offsets from the array scaled by s
  for (let i = 0; i < offsetArray.length; i += 2) {
    hotspot.ctx.drawImage(hotspot.image, x + offsetArray[i] * thicknessScale, y + offsetArray[i + 1] * thicknessScale);
  }

  // fill with color
  const rect = new Path2D();
  rect.rect(padding * -1, padding * -1, hotspot.canvas.width, hotspot.canvas.height);
  hotspot.ctx.globalCompositeOperation = 'source-in';
  hotspot.ctx.fillStyle = color;
  hotspot.ctx.fill(rect);
}

function drawHotspotImage(hotspot: CanvasHotspot) {
  // draw original image in normal mode
  hotspot.ctx.globalCompositeOperation = 'source-over';
  hotspot.ctx.drawImage(hotspot.image, 0, 0);
}

function resizeCanvas() {
  if (!canvasContainer.value || !mainContainer.value || !backgroundLayer.value) return false;
  const mainContainerBounding = mainContainer.value.getBoundingClientRect();
  const sizes = {
    canvas: {
      w: backgroundLayer.value.right || 0,
      h: backgroundLayer.value.bottom || 0,
    },
    mainContainer: {
      w: mainContainerBounding.width,
      h: mainContainerBounding.height,
    },
  };
  const horizontalResizingFactor: number = sizes.mainContainer.w / sizes.canvas.w;
  canvasContainer.value.style.transform = `scale(${Math.min(horizontalResizingFactor, 1)})`;
}

function getHotspotFromClickedItem(item: CanvasHotspot): CanvasHotspot | null {
  let hotspot = item;
  if (item.layer.name === 'blocker') return null;

  if (item.layer.name?.includes('alias:')) {
    const layername = item.layer.name?.replace('alias:', '');
    const originalItem = hotspots.value.find((i) => i.layer.name === layername);
    if (originalItem) {
      hotspot = originalItem;
    }
  }
  return hotspot;
}

async function onClickCanvas(item: CanvasHotspot) {
  changeHotspot(null);
  stopAudioQueue();

  const clickedHotspot = getHotspotFromClickedItem(item);
  console.log('clickedHotspot', clickedHotspot);
  if (clickedHotspot) {
    emit('clickedCanvas', clickedHotspot.layer.name || '', resolveInput);
  }

  function resolveInput(answer: string, status: Status) {
    changeHotspot(clickedHotspot, status, answer);
    activeHotspot.value.item = clickedHotspot;
  }
}

function centerPanzoom() {
  if (panZoomInstance) {
    const zoomLevel = Math.min(4, Math.max(1, 3000 / window.innerWidth));
    panZoomInstance.zoomTo(0, 0, 0);
    panZoomInstance.moveTo(0, 0);
    panZoomInstance.zoomAbs(window.innerWidth * 0.5, window.innerHeight * 0.6, zoomLevel);
  }
}

function createPanZoomInstance() {
  if (mainContainer.value) {
    panZoomInstance = createPanZoom(mainContainer.value, {
      maxZoom: 4.3,
      minZoom: 1,
      bounds: true,
      boundsPadding: 1,
      zoomDoubleClickSpeed: 1,
      initialZoom: 1,
      onClick(e) {
        onClickPanzoom(e);
      },
    });
  }
}

function onClickPanzoom(e: Event) {
  if (e.target instanceof HTMLCanvasElement) {
    const { hotspotId } = e.target.dataset;
    const hotspot = hotspots.value.find((h) => h.id === hotspotId);
    if (hotspot) onClickCanvas(hotspot);
  }
}

function disableZoomOnMobile(e: TouchEvent) {
  // @ts-ignore
  if (e.scale !== 1) {
    e.preventDefault();
  }
}

loadPsd();

onMounted(async () => {
  document.body.classList.add('-blocked-touch');

  // disable zoom on mobile devices
  document.addEventListener('touchmove', disableZoomOnMobile, { passive: false });

  // listen on window resize to resize canvas to 100vw
  window.addEventListener('resize', () => {
    resizeCanvas();
  });
});

onBeforeUnmount(() => {
  document.body.classList.remove('-blocked-touch');
  document.removeEventListener('touchmove', disableZoomOnMobile);
});

</script>

<style>

.placeholder-container,
.panzoom-container {

}

.placeholder-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 1;
  opacity: 1;
  transition: opacity 450ms;
  transition-delay: 150ms;
  pointer-events: none;
  overflow: hidden;
  height: calc(var(--app-height) - 5rem);

  > .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(10px);
    transform: scale(1.6);
  }

  &.-hidden {
    opacity: 0;
  }
}

.panzoom-container {
  width: 100%;
  margin: auto;

  @media (--sm) {
    height: calc(var(--app-height) - 15rem);
  }
  @media (--md) {
    height: calc(var(--app-height) - 10rem);
  }
  @media (--lg-up) {
    height: calc(var(--app-height) - 5rem);
  }

  > .panzoom {
    width: 100%;
    height: 68.52vw;

    > .main {
      transform: scale(1);
      transform-origin: 0 center;
    }

    &.-loading::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 10px solid var(--color-accent-100);
      border-top-color: var(--color-accent-500);
      animation: loading 2s linear infinite;
    }
  }
}

.canvas-container {

  > canvas {
    position: absolute;
    inset: 0;
    caret-color: red;
    cursor: url("/icons/icon-cursor-gray.svg") 21 21, auto;

    &[data-interactive=true] {
      cursor: url("/icons/icon-cursor.svg") 18 18, pointer;
    }
  }

}

.feedback-marker {
  --translate-x: -50%;
  --translate-y: -1rem;

  display: block !important;
  position: absolute;
  user-select: none;
  pointer-events: none;

  &.-hidden {
    visibility: hidden;
  }

  &.-neutral {

    > .label {
      --feedback-image: none;

      color: var(--color-emperor-800);
      background-color: var(--color-emperor-100);

      > .icon {
        display: none;
      }
    }
  }

  &.-negative {

    > .label {
      --feedback-image: url('/icons/icon-badge-wrong.svg');;

      color: var(--color-fruitsalad-100);
      background-color: var(--color-tuscany-100);
      transform: translate(var(--translate-x), -3rem);

      > .icon {
        display: inline-block;
      }
    }
  }

  &.-positive {

    > .label {
      --feedback-image: url('/icons/icon-badge-right.svg');;

      color: var(--color-fruitsalad-900);
      background-color: var(--color-fruitsalad-100);

      > .icon {
        display: inline-block;
      }
    }
  }

  &[label-positionx="right"] {
    --translate-x: 0;
  }

  &[label-positionx="left"] {
    --translate-x: -100%;
  }

  > .label {
    --feedback-image: none;

    position: absolute;
    inset: 0;
    padding: 1rem;
    border-radius: 3rem;
    pointer-events: none;
    font-size: 2rem;
    width: max-content;
    height: fit-content;
    z-index: 2;
    transform: translate(var(--translate-x), var(--translate-y));
    display: flex;
    gap: 1rem;
    align-items: center;
    box-shadow: 0 4px 12px rgba(17, 15, 15, 0.06);

    > .icon {
      width: 1.875rem;
      height: 1.875rem;
      background-image: var(--feedback-image);
      background-position: center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.top-container {
  inset: 0;
  position: absolute;
  height: 0;

  > .container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-emperor-050);
    border-radius: 0 0 var(--base-border-radius-xl) var(--base-border-radius-xl);
    padding: 1.25rem 1.625rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);

    > .button {
      padding: 0;
    }
  }
}

@keyframes visible {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
