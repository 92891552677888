<template>
  <svg
    class="icon -back"
    width="42"
    height="46"
    viewBox="0 0 42 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 34L21 45L41 34V12L21 1L1 12V34Z"
      fill="var(--svg-color-1)"
    />
    <path
      d="M7 20.9999L17 29.9999V24.9999H24L33 30.9999L28.8 20.9999H7Z"
      fill="var(--svg-color-2)"
    />
    <path
      d="M1 34L21 45L41 34L36.8 31.4L21 40L5.2 31.4L1 34Z"
      fill="var(--svg-color-2)"
    />
    <path
      d="M27 16.9999H17V11.9999L7 20.9999L17 29.9999V24.9999H24L33 30.9999L27 16.9999Z"
      stroke="#2F292B"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 34L21 45L41 34V12L21 1L1 12V34Z"
      stroke="#2F292B"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style>
.icon.-back {
  --svg-color-1: var(--color-emperor-050);
  --svg-color-hover-1: var(--color-emperor-200);
  --svg-color-2: var(--color-emperor-200);
  --svg-color-hover-2: var(--color-emperor-400);

  path {
    transition: fill var(--base-transition-duration);
  }
}
</style>
