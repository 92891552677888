import {
  provide, Ref, ref, shallowRef, ShallowRef,
} from 'vue';
import {injectionKey} from '~/utils/inject';
import {LPKImage} from '~/types/lpk';


interface LPKImagesInjection {
  isLoaded: Ref<boolean>;
  images: ShallowRef<LPKImage[]>;
  findImageById: (id: string) => LPKImage | null;
  findImageByName: (name: string) => LPKImage | null;
}

export const LPKImagesInjectionKey = injectionKey<LPKImagesInjection>();

export default function useImages() {
  const images = shallowRef<LPKImage[]>([]);
  const isLoaded = ref<boolean>(false);


  fetchImages()

  async function fetchImages() {
    return fetch('/data-images-lpk.json')
      .then((res) => res.json())
      .then(async (response: LPKImage[]) => {
        images.value = response;
        isLoaded.value = true;
      });
  }

  function findImageById(id: string) {
    if (!images.value || images.value.length <= 0) {
      return null
    }
    return images.value.find(i => i.id === id) ?? null
  }


  function findImageByName(name: string) {
    if (!images.value || images.value.length <= 0) {
      return null
    }
    return images.value.find(i => i.name === name) ?? null
  }

  provide(LPKImagesInjectionKey, {
    images,
    isLoaded,
    findImageById,
    findImageByName,
  });
}
