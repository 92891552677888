import {Ref, ref} from 'vue';
import type {Layer, Psd} from 'ag-psd';
import {readPsd} from 'ag-psd';
import {CanvasHotspot} from '~/types/game';
import * as Sentry from '@sentry/vue';

interface PsdReturn {
  loadPsd: () => void;
  backgroundLayer: Ref<null | Layer>;
  hotspots: Ref<CanvasHotspot[]>;
  isLoaded: Ref<boolean>;
  hasLoadingFailed: Ref<boolean>;
}

export function usePsd(sceneId: string, isNoise = false): PsdReturn {
  const psdData = ref<null | Psd>(null);
  const backgroundLayer = ref<null | Layer>(null);
  const hotspots = ref<CanvasHotspot[]>([]);
  const isLoaded = ref<boolean>(false);
  const hasLoadingFailed = ref<boolean>(false);

  async function loadPsd() {
    return fetch(`/scenes/${sceneId}/photoshop${isNoise ? '-noise' : ''}.psd`)
      .then((response) => response.arrayBuffer())
      .then((buffer) => readPsd(buffer))
      .then((data) => {
        psdData.value = data;
        if (data.children) {
          const [bg] = data.children;
          backgroundLayer.value = bg;
          data.children.slice(1)?.forEach((i) => {
            if (i.canvas) {
              hotspots.value.push({
                layer: i,
                canvas: i.canvas,
                ctx: i.canvas?.getContext('2d') as CanvasRenderingContext2D,
                id: i.name as string,
                image: new Image(),
                imageSrc: i.canvas.toDataURL(),
              });
            }
          });
          isLoaded.value = true;
        }
      })
      .catch((err) => {
        console.error('something went wrong!', err);

        Sentry.captureException(new Error(`Photoshop Data not loaded from scene with id ${sceneId}`));

        hasLoadingFailed.value = true;
      });
  }

  return {
    loadPsd,
    backgroundLayer,
    hotspots,
    isLoaded,
    hasLoadingFailed,
  };
}
