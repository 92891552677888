<template>
  <main class="app-container">
    <RouterView />
    <AudioIndicator />
    <AudioModal />
  </main>
</template>

<script lang="ts" setup>
import AudioIndicator from '~/components/utils/AudioIndicator.vue';
import AudioModal from '~/components/utils/AudioModal.vue';
import useScenes from '~/data/useScenes';
import useExercises from '~/data/useExercises';
import useAuthentication from '~/data/useAuthentication';
import useAudio from '~/data/useAudio';
import {default as useLPKArticles}  from '~/lehrpersonenkommentar/useArticles';
import {default as useLPKScenes}  from '~/lehrpersonenkommentar/useScenes';
import {default as useLPKImages}  from '~/lehrpersonenkommentar/useImages';
import {default as useLPKDocuments}  from '~/lehrpersonenkommentar/useDocuments';

useScenes();
useLPKArticles();
useLPKScenes();
useLPKImages();
useLPKDocuments();
useAudio();
useAuthentication();
useExercises();

</script>

<style>
@import './assets/styles/styles.css';

.app-container {
  height: 100%;
  margin-inline: auto;
}

</style>
