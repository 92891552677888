export default {
  seo: {
    baseTitle: 'Suchbuch',
    baseDescription: 'Wörter, Sätze und Geschichten für die 1. und 2. Klasse und den DaZ-Unterricht. Mit Online-Suchspiel und Lehrpersonenkommentar.',
  },
  buttons: {
    yes: 'Ja',
    no: 'Nein',
    cancel: 'Abbrechen',
    replay: 'Nochmals abspielen',
    back: 'Zurück',
    help: 'Hilfe',
    center: 'Zentrieren',
    newGame: 'Anderes Spiel',
    playAgain: 'Nochmals spielen',
    backToTop: 'nach oben'
  },
  views: {
    suchbuch: {
      login: {
        seoTitle: 'Login Suchspiel',
        header: {
          title: 'Suchbuch',
          subtitle: 'Wörter, Sätze, Geschichte',
        },
        form: {
          title: 'Eingabe Passwort',
          subtitle: 'Suchspiel',
          inputPlaceholder: 'ein-sehr-geheimer-satz',
          inputInfo: 'Bitte geben Sie einmalig das Passwort ein. Dieses finden Sie im Impressum des Suchbuchs. Mehr Informationen auch auf'
        },
      },
      overview: {
        seoTitle: 'Suchspiel Übersicht',
      },
    },
    lehrpersonenkommentar: {
      layout: {
        header: {
          title: 'Suchbuch',
          subtitle: 'Lehrpersonenkommentar',
          linkArticles: 'Kommentar',
          linkScenes: 'Wimmelbilder'
        },
      },
      login: {
        seoTitle: 'Login Lehrpersonenkommentar',
        header: {
          title: 'Suchbuch',
          subtitle: 'Lehrpersonenkommentar',
        },
        form: {
          title: 'Eingabe Passwort',
          subtitle: 'Lehrpersonenkommentar',
          inputPlaceholder: 'ein-sehr-geheimer-satz',
          inputInfo: 'Bitte geben Sie einmalig das Passwort ein. Dieses finden Sie im Impressum des Suchbuchs. Mehr Informationen auch auf'
        },
      },
      overview: {
        seoTitle: 'Übersicht',
        linkArticles: 'Kommentar für Lehrpersonen',
        linkScenes: 'Zusatzmaterial Wimmelbilder'
      },
      overviewArticles: {
        seoTitle: 'Kommentar für Lehrpersonen',
        title: 'Kommentar für Lehrpersonen',
        lead: 'In diesem Bereich finden Sie Informationen und Ideen, um das Suchbuch optimal im Unterricht einzusetzen.'
      },
      detailArticle: {
        seoTitle: 'Login',
      },
      overviewScenes: {
        seoTitle: 'Zusatzmaterial Wimmelbilder',
        title: 'Zusatzmaterial Wimmelbilder',
        lead: 'Hier finden Sie alle Zusatzmaterialien pro Wimmelbild: Geschichten, Sätze, Wortlisten, wiederkehrende Figuren, Tafelmaterial und weiterführende Fragen.'
      },
      detailScene: {
        seoTitle: 'Login',
      },
    },
  },
  words: {
    retryExercise: 'Willst du diese Übung nochmals machen?',
    search_book_title: 'Suchbuch',
    search_book_subtitle_1: 'Wörter, Sätze, Geschichten',
    search_book_subtitle_2: '1. und 2. Klasse',
    licence_code_input_title: 'Eingabe Lizenzcode',
    licence_code_input_placeholder: 'Dein Lizenzcode...',
    licence_code_input_hint: 'Bitte gib einmalig den Lizenzcode ein. Du solltest ihn per Mail erhalten haben.',
    audio_modal_title: 'Auf dieser Webseite wird Audio abgespielt.',
    audio_modal_text: 'Klicke, um weiterzufahren',
    tryAgain: 'Versuch es noch einmal.',
    wellDone: 'Gut gemacht!',
    superWellDone: 'Super gemacht!',
  },
  scenes: {
    city: {
      name: 'Stadt',
      title: 'In der Stadt',
    },
    forest: {
      name: 'Wald',
      title: 'Im Wald',
    },
    water: {
      name: 'Wasser',
      title: 'Am Wasser',
    },
    fair: {
      name: 'Jahrmarkt',
      title: 'Auf dem Jahrmarkt',
    },
    musicroom: {
      name: 'Musikzimmer',
      title: 'Im Musikzimmer / Werkraum',
    },
    birthday: {
      name: 'Geburtstag',
      title: 'Der Geburtstag',
    },
    farm: {
      name: 'Bauernhof',
      title: 'Auf dem Bauernhof',
    },
    childrensroom: {
      name: 'Kinderzimmer',
      title: 'Im Kinderzimmer',
    },
    constructionsite: {
      name: 'Baustelle',
      title: 'Auf der Baustelle',
    },
    parc: {
      name: 'Park',
      title: 'Im Park',
    },
    mall: {
      name: 'Einkaufszentrum',
      title: 'Im Einkaufszentrum',
    },
    quarter: {
      name: 'Quartier',
      title: 'Im Quartier',
    },
    schoolroom: {
      name: 'Schulzimmer',
      title: 'Im Schulzimmer',
    },
    magicland: {
      name: 'Zauberland',
      title: 'Im Zauberland',
    },
    doctor: {
      name: 'Arzt',
      title: 'Beim Arzt',
    },
    schoolexcursion: {
      name: 'Schulreise',
      title: 'Auf der Schulreise',
    },
    sport: {
      name: 'Sport',
      title: 'Beim Sport',
    },
    snow: {
      name: 'Schnee',
      title: 'Im Schnee',
    },
    zoo: {
      name: 'Zoo',
      title: 'Im Zoo',
    },
    playground: {
      name: 'Pausenplatz',
      title: 'Auf dem Pausenplatz',
    },
  },
  exerciseTypes: {
    explore: 'entdecken',
    listen: 'hören',
    read: 'lesen',
    assign: 'zuweisen',
    noise: 'lauschen',
  },
  keys: {
    toothbrush: 'die Zahnbürste',
    dentist_f: 'die Zahnärztin',
    dentist_m: 'der Zahnarzt',
    tooth: 'der Zahn, die Zähne',
    wound: 'die Wunde',
    toolbox: 'die Werkzeugkiste',
    cooler_box: 'die Kühlbox',
    scale: 'die Waage',
    bike: 'das Velo',
    door_handle: 'die Türklinke',
    whistle: 'die Trillerpfeife',
    tear: 'die Träne',
    skull: 'der Totenkopf',
    sculpture: 'die Skulptur',
    hanky: 'das Taschentuch',
    headband: 'das Stirnband',
    stethoscope: 'das Stethoskop',
    injection: 'die Spritze',
    skeleton: 'das Skelett',
    scissors: 'die Schere',
    cream: 'die Salbe',
    backpack: 'der Rucksack',
    'x-ray': 'der Röntgenapparat',
    wheelchair: 'der Rollstuhl',
    patch: 'das Pflaster',
    patches: 'das Pflaster, die Pflaster',
    orchid: 'die Orchidee',
    mobile: 'das Mobile',
    medicine: 'das Medikament, die Medikamente',
    measles: 'die Masern',
    lift: 'der Lift',
    ladder: 'die Leiter',
    cooling_bag: 'der Kühlbeutel',
    crutches: 'die Krücke, die Krücken',
    ambulance: 'der Krankenwagen',
    headscarf: 'das Kopftuch',
    suitcase: 'der Koffer',
    cheese: 'der Käse',
    cactus: 'der Kaktus',
    dog: 'der Hund',
    doghouse: 'die Hundehütte',
    blanked: 'die Bettdecke',
    cat: 'die Katze',
    helicopter: 'der Helikopter',
    bunny: 'der Hase',
    craftsman: 'der Handwerker',
    jumping_jack: 'der Hampelmann',
    hammer: 'der Hammer',
    hammers: 'der Hammer, die Hämmer',
    neckerchief: 'das Halstuch',
    hook: 'der Haken',
    hooks: 'der Haken, die Haken',
    glace: 'die Glace',
    plaster: 'der Gips',
    blood: 'das Blut',
    lead_tar: 'die Bleiweste',
    blue_light: 'das Blaulicht',
    bump: 'die Beule',
    monkey: 'das Äffchen',
    tanker_truck: 'der Tanklastwagen',
    paraglider: 'der Gleitschirm',
    newspaper: 'die Zeitung',
    cow: 'die Kuh',
    boggle: 'die Vogelscheuche',
    plow: 'der Pflug',
    acre: 'der Acker',
    cows: 'die Kuh, die Kühe',
    spate: 'der Spaten',
    hay: 'das Heu',
    garden: 'der Garten',
    garden_zwerg: 'der Gartenzwerg',
    garden_hose: 'der Gartenschlauch',
    garage: 'die Garage',
    flag: 'die Fahne',
    remote_control: 'die Fernsteuerung',
    telescope: 'das Fernrohr',
    shuttlecock: 'der Federball',
    sunflower: 'die Sonnenblume, die Sonnenblumen',
    kalb: 'das Kalb',
    rake: 'der Rechen',
    scythe: 'die Sense',
    pitchfork: 'die Mistgabel',
    dung_heap: 'der Misthaufen',
    harvester: 'der Mähdrescher',
    ram: 'der Schafbock',
    lamb: 'das Lamm',
    goat: 'die Ziege',
    goats: 'die Ziege, die Ziegen',
    sheep: 'das Schaf, die Schafe',
    horse: 'das Pferd',
    donkey: 'der Esel',
    tractor: 'der Traktor',
    car: 'das Auto',
    cow_bell: 'die Kuhglocke, die Kuhglocken',
    cock: 'der Hahn',
    pig: 'das Schwein',
    goose: 'die Gans',
    fork: 'die Gabel',
    noise_truck: 'Der Lastwagen brummt.',
    noise_paraglider: 'Der Gleitschirm flattert.',
    noise_crow: 'Der Krähe krächzt.',
    noise_sheep: 'Die Schafe blöken.',
    noise_horse: 'Das Pferd wiehert.',
    noise_cow: 'Die Kuh muht.',
    noise_donkey: 'Der Esel schreit i-a.',
    noise_dog: 'Der Hund bellt.',
    noise_dog_2: 'Der Hund hechelt.',
    noise_cat: 'Die Katze miaut.',
    noise_moped: 'Das Mofa knattert.',
    noise_milking_machine: 'Die Melkmaschine pumpt.',
    noise_tractor: 'Der Traktor brummt.',
    noise_car: 'Das Auto hupt.',
    noise_cow_bells: 'Die Kuhglocken bimmeln.',
    noise_cock: 'Der Hahn kräht.',
    noise_chicken: 'Das Huhn gackert.',
    noise_pig: 'Das Schwein grunzt.',
    noise_goose: 'Die Gans schnattert.',
    chicken: 'das Huhn',
    straw: 'das Stroh',
    carpenter: 'der Zimmermann',
    spirit_level: 'die Wasserwaage',
    tub: 'die Wanne',
    door: 'die Tür',
    toilet: 'die Toilette',
    power_box: 'der Stromkasten',
    control: 'die Steuerung',
    silo: 'das Silo',
    wheelbarrow: 'die Schubkarre',
    shovel: 'die Schaufel',
    bag: 'der Sack',
    plastic_bag: 'die Tasche',
    rainbow: 'der Regenbogen',
    caterpillar: 'die Raupe',
    wheel_loader: 'der Radlader',
    jackhammer: 'der Presslufthammer',
    plan: 'der Plan',
    measuring_tape: 'das Messband',
    trowel: 'die Maurerkelle',
    bricklayer: 'der Maurer',
    wall: 'die Mauer',
    painter: 'der Maler',
    teacher: 'die Lehrerin',
    teacher_m: 'der Lehrer',
    truck: 'der Lastwagen',
    laptop: 'der Laptop',
    loading_bridge: 'die Ladebrücke',
    crane: 'der Kran',
    fireplace: 'der Kamin',
    cable_reel: 'die Kabelrolle',
    hydrant: 'der Hydrant',
    horseshoe: 'das Hufeisen',
    barbecue: 'der Grill',
    ridge_tree: 'der Firstbaum',
    driver: 'der Fahrer',
    electrician: 'der Elektriker',
    roof_tiles: 'der Dachziegel, die Dachziegel',
    roof_truss: 'der Dachstuhl',
    container: 'der Container',
    concrete_mixer: 'der Betonmischer',
    concrete: 'der Beton',
    excavator: 'der Bagger',
    architect: 'der Architekt',
    waste_dumpster: 'der Abfallmulde',
    waste: 'der Abfall',
    bin: 'der Abfalleimer',
    tree: 'der Baum',
    briefcase: 'der Aktenkoffer',
    pineapple: 'die Ananas, die Ananas',
    banana: 'die Banane',
    bananas: 'die Banane, die Bananen',
    flower_shop: 'das Blumengeschäft',
    pretzel: 'die Brezel',
    fountain: 'der Brunnen',
    book: 'das Buch',
    books: 'das Buch, die Bücher',
    bookstore: 'die Buchhandlung',
    boomerang: 'der Bumerang',
    shopping_basket: 'der Einkaufskorb',
    elephant: 'der Elefant',
    fish: 'der Fisch',
    fontain: 'die Fontäne',
    frog: 'der Frosch',
    frogs: 'der Frosch, die Frösche',
    walking_stick: 'der Gehstock',
    money: 'das Geld',
    vegetable: 'das Gemüse',
    giraffe: 'die Giraffe',
    golf_club: 'der Golfschläger',
    golf_clubs: 'der Golfschläger, die Golfschläger',
    mobile_phone: 'das Handy',
    stroller: 'der Kinderwagen',
    kiosk: 'der Kiosk',
    tie: 'die Krawatte',
    melon: 'die Melone',
    melons: 'die Melone, die Melonen',
    butcher_shop: 'die Metzgerei',
    fashion_store: 'das Modegeschäft',
    stationery: 'die Papeterie',
    peacock: 'der Pfau',
    brush: 'der Pinsel, die Pinsel',
    price_tag: 'das Preisschild',
    doll: 'die Puppe',
    dolls: 'die Puppe, die Puppen',
    escalator: 'die Rolltreppe',
    mannequin: 'die Schaufensterpuppe',
    wrench: 'der Schraubenschlüssel',
    wrenches: 'der Schraubenschlüssel, die Schraubenschlüssel',
    screwdriver: 'der Schraubenzieher',
    screwdrivers: 'der Schraubenzieher, die Schraubenzieher',
    shoe_store: 'das Schuhgeschäft',
    boot: 'der Stiefel, die Stiefel',
    tennis_racket: 'der Tennisschläger',
    tennis_rackets: 'der Tennisschläger, die Tennisschläger',
    squid: 'der Tintenfisch',
    signpost: 'der Wegweiser',
    signposts: 'der Wegweiser, die Wegweiser',
    pliers: 'die Zange',
    pigtail: 'der Zopf, die Zöpfe',
    pigtail_singular: 'der Zopf',
    clock: 'die Uhr',
    noise_clock: 'Die Uhr tickt.',
    drinking_straw: 'der Trinkhalm',
    bowl: 'die Schüssel',
    shoe: 'der Schuh',
    lemon: 'die Zitrone',
    piece_of_paper: 'der Zettel',
    drawing: 'die Zeichnung',
    faucet: 'der Wasserhahn',
    father: 'der Vater',
    vase: 'die Vase',
    baby_bottle: 'der Schoppen',
    bow: 'die Schleife',
    pan: 'die Pfanne',
    snooper: 'der Nuggi',
    mother: 'die Mutter',
    blender: 'der Mixer',
    milk: 'die Milch',
    microwave: 'die Mikrowelle',
    knife: 'das Messer',
    spoon: 'der Löffel',
    pool: 'der Pool',
    paddling_pool: 'das Planschbecken',
    deck_chair: 'der Liegestuhl',
    fridge: 'der Kühlschrank',
    cake: 'der Kuchen',
    stove: 'der Kochherd',
    candles: 'die Kerze, die Kerzen',
    candle: 'die Kerze',
    coffee: 'der Kaffee',
    punsch: 'der Punsch',
    grandfather: 'der Grossvater',
    grandmother: 'die Grossmutter',
    godfather: 'der Götti',
    godmother: 'die Gotte',
    bald: 'die Glatze',
    food_bowl: 'der Fressnapf',
    bucket: 'der Eimer',
    egg: 'das Ei, die Eier',
    butter: 'die Butter',
    brother: 'der Bruder',
    oven: 'der Ofen',
    baking_oven: 'der Backofen',
    baby: 'das Baby',
    wristwatch: 'die Armbanduhr',
    gifts: 'das Geschenk, die Geschenke',
    apple: 'der Apfel',
    apple_juice: 'der Apfelsaft',
    school_bag: 'der Schulsack',
    lamp: 'die Lampe',
    cotton_candy: 'die Zuckerwatte',
    candy_cane: 'die Zuckerstange',
    target: 'die Zielscheibe',
    zebra: 'das Zebra',
    root: 'die Wurzel',
    roots: 'die Wurzel, die Wurzeln',
    easel: 'die Staffelei',
    turtle: 'die Schildkröte',
    panda: 'der Panda',
    orangutan: 'der Orang-Utan',
    beak: 'der Rüssel',
    spittle: 'die Spucke',
    chimp: 'der Schimpanse',
    chimps: 'der Schimpanse, die Schimpansen',
    yeti: 'der Yeti',
    wasp: 'die Wespe',
    grape: 'die Traube, die Trauben',
    spider: 'die Spinne',
    toy_gun: 'die Spielzeugpistole',
    mirror: 'der Spiegel',
    snail: 'die Schnecke',
    jewelry: 'der Schmuck',
    umbrella: 'der Schirm',
    shooting_gallery: 'die Schiessbude',
    ferris_wheel: 'das Riesenrad',
    smoke: 'der Rauch',
    quince: 'die Quitte, die Quitten',
    mast: 'der Mast',
    penguin: 'der Pinguin',
    penguins: 'der Pinguin, die Pinguine',
    noise_penguin: 'Der Pinguin schreit.',
    marroni: 'die Marroni',
    corn_on_the_cob: 'der Maiskolben',
    stomach_bread: 'das Magenbrot',
    jam: 'die Konfitüre',
    carousel: 'das Karussell',
    big_wheel: 'das Hochrad',
    deer: 'der Hirsch',
    rubber_snake: 'die Gummischlange',
    wheel_of_fortune: 'das Glücksrad',
    rifle: 'das Gewehr',
    ghost_train: 'die Geisterbahn',
    bell: 'die Glocke',
    fox: 'der Fuchs',
    barrel: 'das Fass',
    driving_ban: 'das Fahrverbot',
    pickpocket: 'der Taschendieb',
    dragon: 'der Drache',
    duck: 'die Ente',
    ducks: 'die Ente, die Enten',
    lid: 'der Deckel',
    clown: 'der Clown',
    candy: 'das Bonbon',
    snake: 'die Schlange',
    snakes: 'die Schlange, die Schlangen',
    noise_snake: 'Die Schlange zischt.',
    puzzle: 'das Puzzle',
    princess: 'die Prinzessin',
    poster: 'das Poster',
    paper_bin: 'der Papierkorb',
    alarm_clock: 'der Wecker',
    budgie: 'der Wellensittich',
    watercolors: 'die Wasserfarben',
    curtain: 'der Vorhang',
    bird_food: 'das Vogelfutter',
    skipping_rope: 'das Springseil',
    sails: 'das Segel',
    sailboat: 'das Segelboot',
    seal: 'der Seehund',
    drawer: 'die Schublade, die Schubladen',
    pyjamas: 'das Pyjama',
    music_book: 'das Notenheft',
    music_stand: 'der Notenständer',
    hippo: 'das Nilpferd',
    hippos: 'das Nilpferd, die Nilpferde',
    camel: 'das Kamel',
    rhino: 'das Nashorn',
    lama: 'das Lama',
    hump: 'der Höcker',
    microphone: 'das Mikrofon',
    jug: 'der Krug',
    can: 'die Kanne',
    crown: 'die Krone',
    crocodile: 'das Krokodil',
    boy: 'der Knabe',
    kasperli: 'der Kasperli',
    comb: 'der Kamm',
    hairbrush: 'die Bürste',
    cage: 'der Käfig',
    hockey_stick: 'der Hockeystock',
    hairband: 'der Haarreif',
    garland: 'die Girlande',
    flute: 'die Flöte',
    window: 'das Fenster',
    parachute: 'der Fallschirm',
    cd_player: 'der CD-Player',
    cd_case: 'die CD-Hülle',
    cd: 'die CD',
    office_chair: 'der Bürostuhl',
    biscuit: 'das Biskuit, die Biskuits',
    cookie: 'das Guetsli, die Guetsli',
    mug: 'der Becher',
    building_block: 'der Bauklotz, die Bauklötze',
    apricot: 'die Aprikose, die Aprikosen',
    ball: 'der Ball',
    cross: 'das Kreuz',
    icicles: 'der Eiszapfen, die Eiszapfen',
    angel: 'der Engel',
    broom: 'der Besen',
    recorder: 'die Blockflöte',
    drill: 'der Bohrer',
    cimbals: 'die Cimbeln',
    djembe: 'die Djembe',
    violin: 'die Geige',
    viola: 'die Bratsche',
    cello: 'das Cello',
    guitar: 'die Gitarre',
    harp: 'die Harfe',
    bassoon: 'das Fagott',
    clarinet: 'die Klarinette',
    oboe: 'die Oboe',
    kettledrum: 'die Pauke',
    tone_bar: 'der Klangstab',
    double_bass: 'der Kontrabass',
    grand_piano: 'der Konzertflügel',
    grand_piano_piano: 'der Konzertflügel, das Klavier',
    piano: 'das Klavier',
    metronom: 'das Metronom',
    sandpaper: 'das Schleifpapier',
    fretsaw: 'die Laubsäge',
    rasp: 'die Feile',
    vacuum_cleaner: 'der Staubsauger',
    tambourine: 'das Tamburin',
    triangle: 'der Triangel',
    drum: 'die Trommel',
    trumpet: 'die Trompete',
    tuba: 'die Tuba',
    horn: 'das Horn',
    traverse_flute: 'die Querflöte',
    posaune: 'die Posaune',
    xylophone: 'das Xylophon',
    noise_sewing_machine: 'die Nähmaschine',
    noise_rattle: 'die Rassel',
    noise_saxophone: 'das Saxophon',
    saxophone: 'das Saxophon',
    ballon: 'der Ballon',
    birch_tree: 'die Birke',
    bridge: 'die Brücke',
    strawberry: 'die Erdbeere',
    strawberries: 'die Erdbeere, die Erdbeeren',
    fence: 'der Zaun',
    wool: 'die Wolle',
    seesaw: 'die Wippe',
    birds_nest: 'das Vogelnest',
    nest: 'das Nest',
    stairs: 'die Treppe',
    teddy: 'der Teddy',
    teddy_bear: 'der Teddybär',
    fire: 'das Feuer',
    statue: 'die Statue',
    city: 'die Stadt',
    parasol: 'der Sonnenschirm',
    pedestal: 'der Sockel',
    bubble: 'die Seifenblase',
    bubbles: 'die Seifenblase, die Seifenblasen',
    swan: 'der Schwan',
    swans: 'der Schwan, die Schwäne',
    shield: 'das Schild',
    swing: 'die Schaukel',
    sand_cake: 'der Sandkuchen',
    sand_cakes: 'der Sandkuchen, die Sandkuchen',
    sand_castle: 'die Sandburg',
    slide: 'die Rutschbahn',
    rowing_boat: 'das Ruderboot',
    placard: 'das Plakat',
    bad_luck_bird: 'der Pechvogel',
    paper_ship: 'das Papierschiff',
    paddle: 'das Paddel',
    dragonfly: 'die Libelle',
    kiss: 'der Kuss',
    helmet: 'der Helm',
    bottle: 'die Flasche',
    bicycle: 'das Fahrrad',
    bicycles: 'das Fahrrad, die Fahrräder',
    unicycle: 'das Einrad',
    tricycle: 'das Dreirad',
    bread_box: 'der Brotkasten',
    post_box: 'der Briefkasten',
    trailer: 'der Anhänger',
    squirrel: 'das Eichhörnchen',
    blanket: 'die Decke',
    bouquet: 'der Blumenstrauss',
    tongue: 'die Zunge',
    street: 'die Strasse',
    pullover: 'der Pullover',
    goal: 'das Goal',
    roof: 'das Dach',
    beard: 'der Bart',
    bike_rack: 'der Veloständer',
    tau: 'das Tau',
    stilt: 'die Stelze',
    stilts: 'die Stelze, die Stelzen',
    hobbyhorse: 'das Steckenpferd',
    plug: 'der Stecker',
    stick: 'der Stecken',
    sticks: 'der Stecken, die Stecken',
    sportsman: 'der Sportler',
    play_box: 'die Spielkiste',
    sparrow: 'der Spatz',
    sparrows: 'der Spatz, die Spatzen',
    racket: 'der Schläger',
    satellite_dish: 'die Satellitenschüssel',
    lawn_mower: 'der Rasenmäher',
    postman: 'der Postbote',
    carrot: 'das Rüebli',
    tire: 'der Reifen',
    scuffle: 'die Rauferei',
    ping_pong_table: 'der Tischtennistisch',
    snack: 'das Pausenbrot',
    nectarine: 'die Nektarine',
    climbing_frame: 'das Klettergerüst',
    kickboard: 'das Kickboard',
    jojo: 'das Jojo',
    bouncy_game: 'das Hüpfspiel',
    janitor: 'der Hauswart',
    handstand: 'der Handstand',
    gummitwist: 'der Gummitwist',
    belt: 'der Gurt',
    bush: 'der Busch',
    football: 'der Fussball',
    frisbee: 'der Frisbee',
    airplane: 'das Flugzeug',
    cutlery: 'das Besteck',
    parrot: 'der Papagei',
    parrots: 'der Papagei, die Papageien',
    guinea_pig: 'das Meerschweinchen',
    conveyor_belt: 'das Förderband',
    camera: 'der Fotoapparat',
    skateboard: 'das Skateboard',
    hedge_trimmer: 'die Heckenschere',
    moped: 'das Mofa',
    magpie: 'die Elster',
    noise_airplane: 'Das Flugzeug surrt.',
    noise_cutlery: 'Das Besteck scheppert.',
    noise_parrot: 'Der Papagei krächzt.',
    noise_guinea_pig: 'Das Meerscheinchen quiekt.',
    noise_camera: 'Der Fotoapparat klickt.',
    noise_skateboard: 'Andrin fährt Skateboard.',
    noise_hedge_trimmer: 'Die Heckenschere rattert.',
    noise_magpie: 'Die Elster schackert.',
    noise_chimes: 'Das Glockenspiel klingt.',
    noise_stem: 'Die Sprosse knackt.',
    noise_water_dripping: 'Das Wasser tropft.',
    tiger: 'der Tiger',
    balcony: 'der Balkon',
    bob: 'der Bob',
    hockey_ball: 'der Hockeyball',
    hockey_balls: 'der Hockeyball, die Hockeybälle',
    hat: 'der Hut',
    iglu: 'das Iglu',
    sack: 'der Sack',
    tunnel: 'der Tunnel',
    pot: 'der Topf',
    gate: 'das Tor',
    pine: 'die Tanne',
    pines: 'die Tanne, die Tannen',
    tablet: 'die Tafel',
    digital_tablet: 'das Tablet',
    booklet: 'das Heftchen',
    star: 'der Stern',
    stars: 'der Stern, die Sterne',
    snowboard: 'das Snowboard',
    ski_lift: 'der Skilift',
    ski_bob: 'der Skibob',
    ski: 'der Ski, die Ski',
    snow_blower: 'die Schneeschleuder',
    snow_shovel: 'die Schneeschaufel',
    snowplow: 'der Schneepflug',
    snowman: 'der Schneemann',
    snowball: 'der Schneeball',
    snowballs: 'der Schneeball, die Schneebälle',
    skate_bag: 'die Schlittschuhtasche',
    sledge: 'der Schlitten',
    ski_jump: 'die Schanze',
    chisel: 'der Meissel',
    tit: 'die Meise, die Meisen',
    medal: 'die Medaille',
    tangerine: 'die Mandarine',
    chain_of_lights: 'die Lichterkette',
    inline_skates: 'die Inlineskates',
    lifting_ramp: 'die Hebebühne',
    leaves_rake: 'der Laubrechen',
    crib: 'die Krippe',
    shop: 'der Laden',
    compost: 'der Kompost',
    door_bell: 'die Klingel',
    scooter: 'das Kickboard',
    wreath: 'der Kranz',
    christmas_tree: 'der Christbaum',
    stream: 'der Bach',
    bench: 'die Bank',
    mountain: 'der Berg',
    mountaineer: 'der Bergsteiger',
    mountain_bike: 'das Bike',
    bratwurst: 'die Bratwurst',
    firewood: 'das Brennholz',
    cervelat: 'die Cervelat',
    chips: 'die Chips',
    binoculars: 'der Feldstecher',
    field: 'das Feld',
    embers: 'die Glut',
    stop: 'die Haltestelle',
    pile: 'der Haufen',
    hut: 'die Hütte',
    jacket: 'die Jacke',
    cloverleaf: 'das Kleeblatt, die Kleeblätter',
    mouse: 'die Maus',
    nail: 'der Nagel',
    postbus: 'das Postauto',
    robbe: 'die Robbe',
    sack_knife: 'das Sackmesser',
    sandwich: 'das Sandwich',
    mud: 'der Schlamm',
    sweat: 'der Schweiss',
    cable_car: 'die Seilbahn',
    sunglasses: 'die Sonnenbrille',
    woodpecker: 'der Specht',
    lake: 'der See',
    tube: 'die Tube',
    hiker: 'der Wanderer, die Wanderer',
    hiking_trail: 'der Wanderweg',
    snack_box: 'die Znünibox',
    abc: 'das ABC',
    cubes: 'der Würfel, die Würfel',
    blackboard: 'die Wandtafel',
    drop: 'der Tropfen, die Tropfen',
    throne: 'der Thron',
    carpet: 'der Teppich',
    tea: 'der Tee',
    spray_bottle: 'die Sprühflasche',
    sharpener: 'die Spitzmaschine',
    sponge: 'der Schwamm',
    snip: 'die Schnipsel, die Schnipsel',
    shutter: 'der Rollladen',
    raven: 'der Rabe',
    paper_airplane: 'der Papierflieger',
    folders: 'der Ordner, die Ordner',
    lavabo: 'das Lavabo',
    pencil_case: 'das Mäppchen',
    light_switch: 'der Lichtschalter',
    reading_corner: 'die Leseecke',
    glue_stick: 'der Leimstift',
    glue: 'der Leim',
    basket: 'der Korb',
    cherry: 'die Kirsche, die Kirschen',
    canoe: 'das Kanu',
    calendar: 'der Kalender',
    bumblebee: 'die Hummel',
    homework: 'die Hausaufgaben',
    handbag: 'die Handtasche',
    towel: 'das Handtuch',
    globe: 'der Globus',
    watering_can: 'die Giesskanne',
    coat_rack: 'die Garderobe',
    question_mark: 'das Fragezeichen',
    bat: 'die Fledermaus',
    window_sill: 'das Fenstersims',
    computer: 'der Computer',
    roll: 'das Brötchen',
    aim: 'das Ziel',
    tent: 'das Zelt',
    bird: 'der Vogel',
    birds: 'der Vogel, die Vögel',
    trampoline: 'das Trampolin',
    trainer_pants: 'die Trainerhose',
    stopwatch: 'die Stoppuhr',
    springboard: 'das Sprungbrett',
    jump: 'der Sprung',
    wall_bars: 'die Sprossenwand',
    swings_box: 'der Schwedenkasten, die Schwedenkästen',
    ring: 'der Ring',
    rings: 'der Ring, die Ringe',
    high_bar: 'das Reck, die Recks',
    somersault: 'der Purzelbaum',
    pedalo: 'das Pedalo',
    notepad: 'der Notizblock',
    mat_trolley: 'der Mattenwagen',
    paint_stick: 'der Malstab',
    climbing_pole: 'die Kletterstange, die Kletterstangen',
    bouncy_ball: 'der Hüpfball',
    sky: 'der Himmel',
    group: 'die Gruppe',
    glasses: 'die Brille',
    trestle: 'der Bock',
    flower: 'die Blume',
    pencil: 'der Bleistift',
    basketball: 'der Basketball, die Basketbälle',
    bar: 'der Barren',
    exclamation_mark: 'das Ausrufezeichen, die Ausrufezeichen',
    pharmacy: 'die Apotheke',
    burn: 'brennen',
    board: 'einsteigen',
    phone: 'telefonieren',
    drive: 'fahren',
    delete: 'löschen',
    dangerous: 'gefährlich',
    loud: 'laut',
    press: 'pressieren',
    cross_verb: 'überqueren',
    wait: 'warten',
    purple: 'violett',
    full: 'voll',
    streetcar: 'das Tram',
    dinosaur: 'der Dinosaurier',
    railing: 'das Geländer',
    traffic_light: 'die Ampel',
    dumbbell: 'die Hantel',
    church: 'die Kirche',
    pointer: 'der Zeiger',
    pointers: 'der Zeiger, die Zeiger',
    garbage_truck: 'der Müllwagen',
    police_car: 'das Polizeiauto',
    fire_engine: 'das Feuerwehrauto',
    hammock: 'die Hängematte',
    water: 'das Wasser',
    twins: 'die Zwillinge',
    bay_window: 'der Erker',
    grill: 'der Grill',
    raspberry: 'die Himbeere',
    cucumber: 'die Gurke',
    pigeons: 'die Taube, die Tauben',
    waiter: 'der Kellner',
    rain_umbrella: 'der Regenschirm',
    pennant: 'der Wimpel',
    pennants: 'der Wimpel, die Wimpel',
    washing_basket: 'der Wäschekorb',
    hideout: 'das Versteck',
    traffic_table: 'die Verkehrstafel',
    rope_ladder: 'die Strickleiter',
    street_lamp: 'die Strassenlampe',
    sun_clock: 'die Sonnenuhr',
    fixing_pin: 'die Stecknadel',
    fixing_pins: 'die Stecknadel, die Stecknadeln',
    pin: 'die Nadel',
    couch: 'das Sofa',
    manhole: 'der Schacht',
    hedge: 'die Hecke',
    croissant: 'der Gipfel',
    palm: 'die Palme, die Palmen',
    cab: 'das Taxi',
    motorcycle: 'das Motorrad',
    policeman: 'der Polizist, die Polizisten',
    bus: 'der Bus',
    noise_tram: 'Das Tram fährt.',
    noise_church: 'Die Kirchturmuhr schlägt.',
    noise_policecar: 'Die Polizeisirene ertönt.',
    noise_broom: 'Herr Sauber kehrt den Platz.',
    noise_garbage_truck: 'Der Müllwagen fährt ab.',
    noise_fire_truck: 'Die Sirene ertönt.',
    noise_water: 'Das Wasser plätschert.',
    noise_waiter: 'Der Kellner serviert.',
    noise_pigeons: 'Die Taube gurrt.',
    noise_motorcycle: 'Das Motorrad knattert.',
    noise_fire: 'Das Feuer knistert.',
    noise_bus: 'Der Bus fährt ab.',
    rock: 'der Fels',
    worm: 'der Wurm',
    wild_boar: 'das Wildschwein',
    wood_mouse: 'die Waldmaus',
    centipede: 'der Tausendfüssler',
    pine_cone: 'der Tannenzapfen',
    trace: 'die Spur',
    spiders_web: 'das Spinnennetz',
    salamander: 'der Salamander',
    roebuck: 'der Rehbock',
    mole: 'der Maulwurf',
    marten: 'der Marder',
    leash: 'die Leine',
    toad: 'die Kröte',
    seedling: 'der Keimling',
    beetle: 'der Käfer',
    hunter: 'der Jäger',
    high_seat: 'der Hochsitz',
    heap: 'der Haufen',
    collar: 'das Halsband',
    antlers: 'das Geweih',
    walk: 'der Gang',
    forester: 'der Förster',
    butterfly: 'der Schmetterling',
    moth: 'der Falter',
    owl: 'die Eule',
    acorns: 'die Eichel, die Eicheln',
    ivy: 'der Efeu',
    blackberries: 'die Brombeeren',
    stump: 'der Baumstrunk',
    box: 'die Büchse',
    ant: 'die Ameise',
    cloud: 'die Wolke',
    wave: 'die Welle',
    first_aid_kit: 'der Verbandskasten',
    bicycle_helmet: 'der Velohelm',
    diving_goggles: 'die Taucherbrille',
    footbridge: 'der Steg',
    diving_board: 'das Sprungbrett',
    sunburn: 'der Sonnenbrand',
    sun: 'die Sonne',
    starfish: 'der Seestern',
    snorkel: 'der Schnorchel',
    ship: 'das Schiff',
    shadow: 'der Schatten',
    sand_bucket: 'der Sandeimer',
    feather: 'die Feder',
    seagull: 'die Möwe',
    air_mattress: 'die Luftmatratze',
    whale: 'der Wal',
    rubber_boat: 'das Gummiboot',
    oar: 'das Ruder',
    life_ring: 'der Rettungsring',
    bald_head: 'die Glatze',
    fins: 'die Flossen',
    raft: 'das Floss',
    fishing_rod: 'die Angelrute',
    shower: 'die Dusche',
    bee: 'die Biene',
    bathing_cap: 'die Badekappe',
    dwarf: 'der Zwerg',
    scepter: 'das Zepter',
    magic_wand: 'der Zauberstab',
    wizard: 'der Zauberer',
    vampire: 'der Vampir',
    key: 'der Schlüssel',
    lock: 'das Schloss',
    treasure: 'der Schatz',
    coffin: 'der Sarg',
    hourglass: 'die Sanduhr',
    saber: 'der Säbel',
    knight: 'der Ritter',
    giant: 'der Riese',
    robber: 'der Räuber',
    prince: 'der Prinz',
    pistol: 'die Pistole',
    pirate: 'der Pirat',
    pickaxe: 'der Pickel',
    easter_bunny: 'der Osterhase',
    mermaid: 'die Nixe',
    monster: 'das Monster, die Monster',
    mane: 'die Mähne',
    lantern: 'die Laterne',
    easter_eggs: 'das Osterei, die Ostereier',
    lance: 'die Lanze',
    queen: 'die Königin',
    king: 'der König',
    goblin: 'der Kobold',
    cauldron: 'der Kessel',
    cannon: 'die Kanone',
    ghost: 'das Gespenst',
    fairy: 'die Fee',
    torch: 'die Fackel',
    unicorn: 'das Einhorn',
    kangaroo: 'das Känguru',
    flamingo: 'der Flamingo',
    flamingos: 'der Flamingo, die Flamingos',
    stork: 'der Storch',
    lion: 'der Löwe',
    lions: 'der Löwe, die Löwen',
    noise_bagger: 'Der Bagger schaufelt.',
    noise_felix_crying: 'Felix weint.',
    noise_kangaroo: 'Das Känguru schnalzt.',
    noise_donkey_2: 'Der Esel schreit.',
    noise_tiger: 'Der Tiger faucht.',
    noise_flamingo: 'Der Flamingo schnattert.',
    noise_stork: 'Der Storch klappert mit dem Schnabel.',
    noise_wheelbarrow: 'Die Schubkarre quietscht.',
    noise_goat: 'Die Ziege meckert.',
    noise_lion: 'Der Löwe brüllt.',
    noise_elephant: 'Der Elefant trompetet.',
    noise_monkey: 'Der Affe schreit.',
    verb_bake: 'backen',
    verb_draw: 'zeichnen',
    verb_greet: 'begrüssen',
    verb_fry: 'braten',
    verb_bring: 'bringen',
    verb_board: 'einsteigen',
    verb_eat: 'essen',
    verb_fly: 'fliegen',
    verb_feed: 'füttern',
    verb_go: 'gehen',
    verb_juggle: 'jonglieren',
    verb_sweep: 'kehren',
    verb_laugh: 'lachen',
    verb_read: 'lesen',
    verb_lie: 'liegen',
    verb_whistle: 'pfeifen',
    verb_peck: 'picken',
    verb_hurry: 'pressieren',
    verb_race: 'rasen',
    verb_talk: 'reden',
    verb_run: 'rennen',
    verb_running: 'laufen',
    verb_call: 'rufen',
    verb_look: 'schauen',
    verb_sleep: 'schlafen',
    verb_licking: 'schlecken',
    verb_scream: 'schreien',
    verb_serve: 'servieren',
    verb_serve_2: 'bedienen',
    verb_give: 'geben',
    verb_give_2: 'schenken',
    verb_sit: 'sitzen',
    verb_play: 'spielen',
    verb_jump: 'springen',
    verb_amazement: 'staunen',
    verb_stand: 'stehen',
    verb_steer: 'steuern',
    verb_knit: 'stricken',
    verb_phone: 'telefonieren',
    verb_carry: 'tragen',
    verb_tread: 'treten',
    verb_wait: 'warten',
    verb_wave: 'winken',
    verb_show: 'zeigen',
    verb_pull: 'ziehen',
    verb_stretch_out: 'aufstrecken',
    verb_color: 'ausmalen',
    verb_tinker: 'basteln',
    verb_bloom: 'blühen',
    verb_explain: 'erklären',
    verb_flutter: 'flattern',
    verb_help: 'helfen',
    verb_giggle: 'kichern',
    verb_concentrate: 'konzentrieren',
    verb_crawl: 'kriechen',
    verb_scrawl: 'kraulen',
    verb_meow: 'miauen',
    verb_paddle: 'paddeln',
    verb_croak: 'quaken',
    verb_calculate: 'rechnen',
    verb_repair: 'reparieren',
    verb_write: 'schreiben',
    verb_chatter: 'schwatzen',
    verb_chatter_2: 'schnattern',
    verb_hover: 'schweben',
    verb_point: 'spitzen',
    verb_tick: 'ticken',
    verb_gymnastics: 'turnen',
    verb_superior: 'überlegen',
    verb_grow: 'wachsen',
    verb_throw: 'werfen',
    verb_pickup: 'abholen',
    verb_hangup: 'aufhängen',
    verb_unload: 'ausladen',
    verb_bark: 'bellen',
    verb_break: 'brechen',
    verb_turn: 'drehen',
    verb_idle: 'faulenzen',
    verb_photograph: 'fotografieren',
    verb_water: 'giessen',
    verb_lift: 'hochheben',
    verb_ring: 'klingeln',
    verb_squawk: 'krächzen',
    verb_drag: 'schleppen',
    verb_cut: 'schneiden',
    verb_sniff: 'schnuppern',
    verb_chat: 'schwatzen',
    verb_skate: 'skaten',
    verb_splash: 'spritzen',
    verb_steal: 'stehlen',
    verb_wear: 'tragen',
    verb_dry: 'trocknen',
    verb_drop: 'tropfen',
    verb_sell: 'verkaufen',
    verb_hide: 'verstecken',
    adj_old: 'alt',
    adj_bearded: 'bärtig',
    adj_decorated: 'dekoriert',
    adj_triangular: 'dreieckig',
    adj_thirsty: 'durstig',
    adj_amazed: 'erstaunt',
    adj_fresh: 'frisch',
    adj_cheerful: 'fröhlich',
    adj_folded: 'gefaltet',
    adj_dotted: 'gepunktet',
    adj_striped: 'gestreift',
    adj_jagged: 'gezackt',
    adj_glittery: 'glitzerig',
    adj_hot: 'heiss',
    adj_young: 'jung',
    adj_bald: 'kahl',
    adj_cold: 'kalt',
    adj_checkered: 'kariert',
    adj_sticky: 'klebrig',
    adj_blank: 'leer',
    adj_wet: 'nass',
    adj_open: 'offen',
    adj_oval: 'oval',
    adj_round: 'rund',
    adj_clean: 'sauber',
    adj_acid: 'sauer',
    adj_pregnant: 'schwanger',
    adj_pointed: 'spitzig',
    adj_sweet: 'süss',
    adj_square: 'viereckig',
    adj_full: 'voll',
    adj_torn: 'zerrissen',
    verb_blow: 'blasen',
    verb_blow_sneeze: 'schnäuzen',
    verb_drill: 'bohren',
    verb_patch: 'flicken',
    verb_blades: 'klingen',
    verb_knead: 'kneten',
    verb_praise: 'loben',
    verb_paint: 'malen',
    verb_sew: 'nähen',
    verb_sawing: 'sägen',
    verb_suck: 'saugen',
    verb_cutting: 'schneiden',
    verb_screws: 'schrauben',
    verb_shake: 'schütteln',
    verb_singing: 'singen',
    verb_stomp: 'stampfen',
    verb_stab: 'stechen',
    verb_dance: 'tanzen',
    verb_weave: 'weben',
    verb_tease: 'ärgern',
    verb_laughat: 'auslachen',
    verb_admire: 'bewundern',
    verb_catch: 'fangen',
    verb_fill: 'füllen',
    verb_fill_up: 'einfüllen',
    verb_hop: 'hüpfen',
    verb_jog: 'joggen',
    verb_fight: 'kämpfen',
    verb_crouch: 'kauern',
    verb_climb: 'klettern',
    verb_reading: 'lesen',
    verb_lying: 'liegen',
    verb_scuffle: 'raufen',
    verb_shout: 'rufen',
    verb_swim: 'schwimmen',
    verb_swap: 'tauschen',
    verb_gossip: 'tuscheln',
    verb_cry: 'weinen',
    verb_watch: 'zuschauen',
    verb_observe: 'beobachten',
    verb_burn: 'brennen',
    verb_gawk: 'glotzen',
    verb_dig: 'graben',
    verb_bury: 'einbuddeln',
    verb_hunt: 'jagen',
    verb_nibble: 'knabbern',
    verb_scuttle: 'krabbeln',
    verb_gnaw: 'nagen',
    verb_tubes: 'röhren',
    verb_collect: 'sammeln',
    verb_shoot: 'schiessen',
    verb_sneak: 'schleichen',
    verb_peek: 'spähen',
    verb_spray: 'sprayen',
    verb_scent: 'wittern',
    verb_unroll: 'abrollen',
    verb_cheerup: 'aufmuntern',
    verb_balance: 'balancieren',
    verb_jitter: 'bibbern',
    verb_dribble: 'dribbeln',
    verb_win: 'gewinnen',
    verb_hold: 'halten',
    verb_hang: 'hängen',
    verb_whine: 'jammern',
    verb_land: 'landen',
    verb_bounce: 'prellen',
    verb_slide: 'rutschen',
    verb_hit: 'treffen',
    verb_lose: 'verlieren',
    verb_tremble: 'zittern',
    verb_bleat: 'blöken',
    verb_buck: 'bocken',
    verb_stoop: 'bücken',
    verb_drive: 'fahren',
    verb_mend: 'flicken',
    verb_munch: 'fressen',
    verb_guide: 'führen',
    verb_cackle: 'gackern',
    verb_pour: 'giessen',
    verb_grunt: 'grunzen',
    verb_crow: 'krähen',
    verb_mow: 'mähen',
    verb_grumble: 'meckern',
    verb_milk: 'melken',
    verb_moo: 'muhen',
    verb_pick: 'pflücken',
    verb_plow: 'pflügen',
    verb_riding: 'reiten',
    verb_push: 'schieben',
    verb_scatter: 'streuen',
    verb_neigh: 'wiehern',
    adj_similarto: 'ähnlich',
    adj_timid: 'ängstlich',
    adj_cheap: 'billig',
    adj_colorful: 'bunt',
    adj_cheeky: 'frech',
    adj_patient: 'geduldig',
    adj_clever: 'geschickt',
    adj_poisonous: 'giftig',
    adj_happy: 'glücklich',
    adj_creepy: 'gruselig',
    adj_deceitful: 'hinterlistig',
    adj_high: 'hoch',
    adj_hungry: 'hungrig',
    adj_strong: 'stark',
    adj_powerful: 'kräftig',
    adj_slow: 'langsam',
    adj_light: 'leicht',
    adj_light_light: 'hell',
    adj_tired: 'müde',
    adj_mature: 'reif',
    adj_juicy: 'saftig',
    adj_fast: 'schnell',
    adj_spiky: 'spitzig',
    adj_nice: 'schön',
    adj_prickly: 'stachelig',
    adj_tattooed: 'tätowiert',
    adj_expensive: 'teuer',
    adj_sad: 'traurig',
    adj_bad: 'übel',
    adj_angry: 'verärgert',
    adj_furious: 'wütend',
    adj_amused: 'vergnügt',
    adj_warm: 'warm',
    adj_windy: 'windig',
    adj_flexible: 'beweglich',
    adj_blond: 'blond',
    adj_transparent: 'durchsichtig',
    adj_dangerous: 'gefährlich',
    adj_plagued: 'geplagt',
    adj_curled: 'geringelt',
    adj_mackerel: 'getigert',
    adj_spotted: 'getupft',
    adj_halved: 'halbiert',
    adj_pink: 'rosa',
    adj_stormy: 'stürmisch',
    adj_soft: 'weich',
    verb_tryon: 'anprobieren',
    verb_queue: 'anstehen',
    verb_fillup: 'auffüllen',
    verb_pay: 'bezahlen',
    verb_pack: 'einpacken',
    verb_ask: 'fragen',
    verb_buy: 'kaufen',
    verb_taste: 'probieren',
    verb_beat: 'schlagen',
    verb_consider: 'überlegen',
    verb_choose: 'wählen',
    verb_repel: 'abwehren',
    verb_lineup: 'aufstellen',
    verb_slip: 'ausrutschen',
    verb_build: 'bauen',
    verb_bob: 'Bob fahren',
    verb_steam: 'dampfen',
    verb_duck: 'ducken',
    verb_film: 'filmen',
    verb_freeze: 'frieren',
    verb_glide: 'gleiten',
    verb_glow: 'leuchten',
    verb_chisel: 'meisseln',
    verb_whizz: 'sausen',
    verb_toboggan: 'schlitteln',
    verb_snow: 'schneien',
    verb_skiing: 'Ski fahren',
    verb_snowboarding: 'snowboarden',
    verb_trudge: 'stapfen',
    verb_bath: 'baden',
    verb_roar: 'brüllen',
    verb_yawn: 'gähnen',
    verb_kneel: 'knien',
    verb_clean: 'reinigen',
    verb_swing: 'schaukeln',
    verb_drink: 'trinken',
    verb_fall: 'fallen',
    verb_flee: 'flüchten',
    verb_sparkle: 'funkeln',
    verb_cook: 'kochen',
    verb_smoke: 'qualmen',
    verb_rule: 'regieren',
    verb_ride: 'reiten',
    verb_trickle: 'rieseln',
    verb_drool: 'sabbern',
    verb_swimming: 'schwimmen',
    verb_haunt: 'spuken',
    verb_marvel: 'staunen',
    verb_embroider: 'sticken',
    verb_bow: 'verneigen',
    verb_conjure: 'zaubern',
    verb_silly: 'albern',
    verb_flash: 'blinken',
    verb_bleed: 'bluten',
    verb_disinfect: 'desinfizieren',
    verb_scare: 'erschrecken',
    verb_cough: 'husten',
    verb_cool: 'kühlen',
    verb_x_ray: 'röntgen',
    verb_shiver: 'schlottern',
    verb_transport: 'transportieren',
    verb_comfort: 'trösten',
    verb_examine: 'untersuchen',
    verb_connect: 'verbinden',
    verb_warm: 'wärmen',
    verb_whimper: 'winseln',
    verb_find: 'finden',
    verb_curse: 'fluchen',
    verb_listen: 'hören',
    verb_kiss: 'küssen',
    verb_grab: 'packen',
    verb_pump: 'pumpen',
    verb_gift: 'schenken',
    verb_walk: 'spazieren',
    verb_stink: 'stinken',
    verb_knitting: 'stricken',
    verb_search: 'suchen',
    verb_rocking: 'wippen',
    verb_chirp: 'zwitschern',
    verb_discuss: 'besprechen',
    verb_concreting: 'betonieren',
    verb_grill: 'grillieren',
    verb_climbing: 'klettern',
    verb_painting: 'malen',
    verb_wall: 'mauern',
    verb_measure: 'messen',
    verb_nail: 'nageln',
    verb_plan: 'planen',
    verb_shovel: 'schaufeln',
    verb_control: 'steuern',
    verb_support: 'stützen',
    verb_perform: 'vortragen',
    verb_declare: 'erklären',
    verb_snap: 'schnappen',
    verb_spit: 'spucken',
    verb_stroke: 'streicheln',
    verb_dive: 'tauchen',
    adj_barefoot: 'barfuss',
    adj_horrified: 'entsetzt',
    adj_fit: 'fit',
    adj_yellow: 'gelb',
    adj_mean: 'gemein',
    adj_crispy: 'knusprig',
    adj_long_sleeved: 'langärmlig',
    adj_salty: 'salzig',
    adj_sharp: 'scharf',
    adj_painful: 'schmerzhaft',
    adj_dirty: 'schmutzig',
    adj_black: 'schwarz',
    adj_heavy: 'schwer',
    adj_sporty: 'sportlich',
    adj_steep: 'steil',
    adj_four_leaf: 'vierblättrig',
    verb_dryoff: 'abtrocknen',
    verb_inflate: 'aufblasen',
    'verb_dig in': 'einbuddeln',
    verb_creaming: 'eincremen',
    verb_fishing: 'fischen',
    verb_cheer: 'jubeln',
    verb_rowing: 'rudern',
    verb_lick: 'schlecken',
    verb_snorkel: 'schnorcheln',
    verb_sweating: 'schwitzen',
    verb_sailing: 'segeln',
    verb_sting: 'stechen',
    verb_surf: 'surfen',
    verb_diving: 'tauchen',
    verb_changeclothes: 'sich umziehen',
  },
};
