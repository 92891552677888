import {
  provide, Ref, ref, shallowRef, ShallowRef,
} from 'vue';
import {injectionKey} from '~/utils/inject';
import {LPKDocument} from '~/types/lpk';


interface LPKDocumentsInjection {
  isLoaded: Ref<boolean>;
  documents: ShallowRef<LPKDocument[]>;
  findDocumentById: (id: string) => LPKDocument | null;
  findDocumentByName: (name: string) => LPKDocument | null;
}

export const LPKDocumentsInjectionKey = injectionKey<LPKDocumentsInjection>();

export default function useDocuments() {
  const documents = shallowRef<LPKDocument[]>([]);
  const isLoaded = ref<boolean>(false);


  fetchDocuments()

  async function fetchDocuments() {
    return fetch('/data-documents-lpk.json')
      .then((res) => res.json())
      .then(async (response: LPKDocument[]) => {
        documents.value = response;
        isLoaded.value = true;
      });
  }

  function findDocumentById(id: string) {
    if (!documents.value || documents.value.length <= 0) {
      return null
    }
    return documents.value.find(i => i.id === id) ?? null
  }

  function findDocumentByName(name: string) {
    if (!documents.value || documents.value.length <= 0) {
      return null
    }
    return documents.value.find(i => i.name === name) ?? null
  }

  provide(LPKDocumentsInjectionKey, {
    documents,
    isLoaded,
    findDocumentById,
    findDocumentByName,
  });
}
