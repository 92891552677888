import {
  provide, Ref, ref, shallowRef, ShallowRef,
} from 'vue';
import {injectionKey} from '~/utils/inject';
import {LPKArticle} from '~/types/lpk';


interface LPKArticlesInjection {
  isLoaded: Ref<boolean>;
  articles: ShallowRef<LPKArticle[]>;
  findArticleById: (id: string) => LPKArticle | null;
  findArticleBySlug: (slug: string) => LPKArticle | null;
}

export const LPKArticlesInjectionKey = injectionKey<LPKArticlesInjection>();

export default function useArticles() {
  const articles = shallowRef<LPKArticle[]>([]);
  const isLoaded = ref<boolean>(false);


  fetchArticles()

  async function fetchArticles() {
    return fetch('/data-articles-lpk.json')
      .then((res) => res.json())
      .then(async (response: LPKArticle[]) => {
        articles.value = response;
        isLoaded.value = true;
      });
  }

  function findArticleById(id: string) {
    if (!articles.value || articles.value.length <= 0) {
      return null
    }
    return articles.value.find(a => a.id === id) ?? null
  }

  function findArticleBySlug(slug: string) {
    if (!articles.value || articles.value.length <= 0) {
      return null
    }
    return articles.value.find(a => a.slug === slug) ?? null
  }

  provide(LPKArticlesInjectionKey, {
    articles,
    isLoaded,
    findArticleById,
    findArticleBySlug
  });
}
