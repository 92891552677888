<template>
  <svg
    class="icon -help"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3367 23.33L16.0033 30.6633L2.67 23.33V8.66332L16.0033 1.32999L29.3367 8.66332V23.33Z"
      fill="var(--svg-color-1)"
    />
    <path
      d="M26.67 21.33L16.0033 27.33L5.33666 21.33L2.67 23.33L16.0033 30.6633L29.3367 23.33L26.67 21.33Z"
      fill="var(--svg-color-2)"
    />
    <path
      d="M17.3367 18.6633V16.6633L21.3367 13.9966V9.33004L16.0033 5.73004L10.67 9.06337V11.9967L16.0033 8.66338L18.67 10.6634V12.6633L14.67 15.33V18.6633H17.3367Z"
      fill="var(--svg-color-2)"
    />
    <path
      d="M15.9088 21.3501L13.9289 23.33L15.9088 25.3099L17.8887 23.33L15.9088 21.3501Z"
      fill="var(--svg-color-2)"
    />
    <path
      d="M29.3367 23.33L16.0033 30.6633L2.67 23.33V8.66332L16.0033 1.32999L29.3367 8.66332V23.33Z"
      stroke="#392442"
      stroke-width="1.33333"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.3367 18.6633V16.6633L21.3367 13.9966V9.33004L16.0033 5.73004L10.67 9.06337V11.9967L16.0033 8.66338L18.67 10.6634V12.6633L14.67 15.33V18.6633H17.3367Z"
      stroke="#392442"
      stroke-width="1.33333"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9088 21.3501L13.9289 23.33L15.9088 25.3099L17.8887 23.33L15.9088 21.3501Z"
      stroke="#392442"
      stroke-width="1.33333"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style>

.icon.-help {
  --svg-color-1: var(--color-affair-050);
  --svg-color-hover-1: var(--color-affair-200);
  --svg-color-2: var(--color-affair-200);
  --svg-color-hover-2: var(--color-affair-400);

  path {
    transition: fill var(--base-transition-duration);
  }
}
</style>
