import { provide, Ref, ref } from 'vue';
import {AuthEntry, authLogin} from '~/authentication/authentication.js';
import { injectionKey } from '~/utils/inject';

interface AuthInjection {
  isLoggedIn: Ref<boolean>;
  loginLpk: (token: string) => boolean;
  loginSuchbuch: (token: string) => boolean;
}

export const AuthInjection = injectionKey<AuthInjection>();

export default function useAuthentication() {
  const isLoggedIn = ref<boolean>(false);

  function loginLpk(token: string) {
    isLoggedIn.value = authLogin(token, AuthEntry.LPK);
    return isLoggedIn.value;
  }

  function loginSuchbuch(token: string) {
    isLoggedIn.value = authLogin(token, AuthEntry.Suchbuch);
    return isLoggedIn.value;
  }


  provide(AuthInjection, {
    isLoggedIn,
    loginLpk,
    loginSuchbuch,
  });

  return {};
}
