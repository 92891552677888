const tokenSuchbuch = 'suchbuch';
const tokenLPK = 'suchbuch-lp';

export enum AuthEntry {
  LPK = 'lpk',
  Suchbuch = 'suchbuch',
}

export function checkAuthStatus(entry: AuthEntry): boolean {
  if (entry === AuthEntry.LPK) {
    const storedToken = localStorage.getItem('token_lmv_lpk');
    if(!storedToken){
      return false
    }
    return storedToken === tokenLPK;
  }
  const storedToken = localStorage.getItem('token_lmv_suchbuch');
  if(!storedToken){
    return false
  }
  return storedToken === tokenSuchbuch;
}

export function authLogin(loginToken: string, entry: AuthEntry): boolean {
  if (entry === AuthEntry.LPK) {
    if (tokenLPK === loginToken) {
      localStorage.setItem('token_lmv_lpk', loginToken);
    } else {
      localStorage.removeItem('token_lmv_lpk');
    }
    return checkAuthStatus(entry);
  }

  if (tokenSuchbuch === loginToken) {
    localStorage.setItem('token_lmv_suchbuch', loginToken);
  } else {
    localStorage.removeItem('token_lmv_suchbuch');
  }
  return checkAuthStatus(entry);
}
