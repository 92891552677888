import { provide, Ref, ref } from 'vue';
import { injectionKey } from '~/utils/inject';

export const AudioStreamInjection = injectionKey<{
  playAudioSrc:(src: string, options?: { immediate: boolean }) => void;
  stopAudioQueue: () => void;
  isAudioContextSuspended: Ref<boolean>;
  isPlaying: Ref<boolean>;

}>();

export default function useAudio() {
  const isPlaying = ref<boolean>(false);
  const isAudioContextSuspended = ref<boolean>(false);
  const queue = ref<HTMLAudioElement[]>([]);
  let audioCtx: AudioContext | null = null;

  function playAudioSrc(src: string, options: { immediate: boolean } = { immediate: false }) {
    if (src === undefined) {
      return;
    }
    if (options?.immediate) {
      stopAudioQueue();
    }
    if (queue.value.length > 4) return;
    let path = `../../../${src}`;
    if (!path.endsWith('.mp3')) path += '.mp3';
    const audioUrl = new URL(path, import.meta.url).href;
    const audio = new Audio(audioUrl);
    queue.value.push(audio);

    onQueueUpdate();
  }

  async function onQueueUpdate() {
    checkAudioContext();
    if (isAudioContextSuspended.value) {
      return;
    }
    if (isPlaying.value) {
      return;
    }
    const currentAudio = queue.value[0];
    if (currentAudio) {
      isPlaying.value = true;
      try {
        await currentAudio.play();
        await playAudio(currentAudio);
        isPlaying.value = false;
        queue.value.shift();
        onQueueUpdate();
      } catch (e) {
        console.error('could not play audio');
      }
    }
  }

  function checkAudioContext() {
    if (!audioCtx) {
      audioCtx = new AudioContext();
      if (audioCtx.state === 'suspended') {
        isAudioContextSuspended.value = true;
      }
    }
  }

  function playAudio(audio: HTMLAudioElement): Promise<true> {
    return new Promise((resolve) => {
      audio.addEventListener('ended', () => {
        resolve(true);
      });
    });
  }

  function stopAudioQueue() {
    if (isPlaying.value && queue.value[0]) {
      queue.value[0].pause();
      queue.value[0].currentTime = 0;
    }
    queue.value = [];
    isPlaying.value = false;
  }

  provide(AudioStreamInjection, {
    stopAudioQueue,
    playAudioSrc,
    isAudioContextSuspended,
    isPlaying,
  });

  return {};
}
