<template>
  <Teleport to="body">
    <div
      v-if="isAudioContextSuspended"
      class="modal-container"
      @click.self="toggleOpen()"
      @keydown="toggleOpen()"
    >
      <div class="content modal-content">
        <div class="main">
          <div class="containeraudiobars">
            <span class="audio-bars">
              <span class="bar" />
              <span class="bar" />
              <span class="bar" />
              <span class="bar" />
            </span>
          </div>
          <p class="h3 title">
            Audio-Ausgabe
          </p>
          <p class="text">
            Das Suchbuch benötigt für einige Aufgaben und Hilfestellungen Ton-Ausgaben. Wenn du oben links am Bildschirm
            den Audio-Indikator siehst, dann wird Audio abgespielt.
          </p>
        </div>
        <div class="footer">
          <ButtonIcon
            class="button"
            :icon="'forward'"
            :label="'Alles klar'"
            @click="toggleOpen"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import ButtonIcon from '~/components/utils/ButtonIcon.vue';
import { injectStrict } from '~/utils/inject';
import { AudioStreamInjection } from '~/data/useAudio';

const { isAudioContextSuspended } = injectStrict(AudioStreamInjection);

function toggleOpen() {
  isAudioContextSuspended.value = false;
}

defineExpose({
  toggleOpen,
});

</script>

<style scoped>

.modal-content {

  > .main {

    > .containeraudiobars,
    > .title {
      display: inline-block;
    }

    > .containeraudiobars {
      width: 2rem;
      height: 3rem;
      margin-right: 1rem;
    }

    > .title {
      @mixin text-lg;

      padding: 0.5rem 0 1rem 0;

      > .icon {
        width: 1.1rem;
        height: 1.1rem;

        > .image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    > .text {

    }
  }

  > .footer {
    background-color: var(--color-white);

    > .button {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

</style>
