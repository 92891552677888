import {
  Component, provide, shallowRef, ShallowRef,
} from 'vue';
import ExploreExercise from '~/components/exercises/ExploreExercise.vue';
import AssignExercise from '~/components/exercises/AssignExercise.vue';
import ReadExercise from '~/components/exercises/ReadExercise.vue';
import ListenExercise from '~/components/exercises/ListenExercise.vue';
import NoiseExercise from '~/components/exercises/NoiseExercise.vue';
import {injectionKey} from '~/utils/inject';

export type ExerciseType = 'explore' | 'listen' | 'read' | 'assign' | 'noise'

export type TaskType = 'puzzle' | 'assign' | 'noise'

export interface Exercise {
  type: ExerciseType;
  component: Component;
  audioInstructionSrc: string;
  audioInstructionTouchSrc: string;
  goal?: number;
  taskType: TaskType;
  color: string;
}

interface ExerciseInjection {
  exerciseTypes: ExerciseType[];
  exercises: Exercise[];
  currentExercise: ShallowRef<null | Exercise>;
  setCurrentExercise: (exercise: null | Exercise) => void;
}

export const ExerciseInjection = injectionKey<ExerciseInjection>();

export default function useExercises() {
  // variables
  const exercises: Exercise[] = [];
  const exerciseComponents: { [key: string]: Component } = {
    explore: ExploreExercise,
    assign: AssignExercise,
    read: ReadExercise,
    listen: ListenExercise,
    noise: NoiseExercise,
  };
  const exerciseTypes: ExerciseType[] = ['explore', 'listen', 'read', 'assign', 'noise'];
  const exerciseColors: string[] = ['tuscany', 'goldtips', 'fruitsalad', 'shipcove', 'affair'];

  // init
  exerciseTypes.forEach((type, i) => {
    let taskType: TaskType = 'puzzle';
    if (type === 'assign') {
      taskType = 'assign'
    } else if (type === 'noise') {
      taskType = 'noise'
    }

    const audioInstructionSrc = `audio/instructions/exercise-${type}`
    const audioInstructionTouchSrc = type === 'explore' ? `${audioInstructionSrc}-touch` : audioInstructionSrc;

    exercises.push({
      type,
      goal: type === 'explore' ? undefined : 10,
      component: exerciseComponents[type] as Component,
      audioInstructionSrc: audioInstructionSrc,
      audioInstructionTouchSrc: audioInstructionTouchSrc,
      taskType,
      color: exerciseColors[i],
    });
  });

  // refs
  const currentExercise = shallowRef<null | Exercise>(null);

  // functions
  function setCurrentExercise(exercise: Exercise | null) {
    currentExercise.value = exercise;
  }

  provide(ExerciseInjection, {
    exerciseTypes,
    exercises,
    currentExercise,
    setCurrentExercise,
  });

  return {};
}
