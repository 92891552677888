<template>
  <div
    class="audio-indicator"
    :class="[theme, { '-active': isPlaying }]"
  >
    <span class="audio-bars">
      <span class="bar" />
      <span class="bar" />
      <span class="bar" />
      <span class="bar" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { injectStrict } from '~/utils/inject';
import { AudioStreamInjection } from '~/data/useAudio';

const { isPlaying } = injectStrict(AudioStreamInjection);

const theme = ref<'-lighter' | '-darker'>('-lighter');

</script>

<style scoped>

.audio-indicator {
  position: fixed;
  z-index: 100;
  inset: 0 auto auto 2rem;
  height: 6.5rem;
  width: 2.625rem;
  border-radius: 0 0 var(--base-border-radius-xl) var(--base-border-radius-xl);
  display: flex;
  transition: transform var(--base-transition-duration);
  transform: translateY(-6.5rem);
  box-shadow: 0 4px 12px rgba(17, 15, 15, 0.06);

  &.-lighter {
    background-color: var(--color-emperor-100);
  }

  &.-darker {
    background-color: var(--color-emperor-300);
  }

  &.-active {
    transform: translateY(0);
  }
}

</style>
