import {
  computed, ComputedRef, provide, Ref, ref, shallowRef, ShallowRef,
} from 'vue';
import {useRoute} from 'vue-router';
import {injectionKey} from '~/utils/inject';
import {Scene} from '~/types/scenes';

interface SceneInjection {
  isScenesLoaded: Ref<boolean>;
  scenes: ShallowRef<Scene[]>;
  currentScene: ComputedRef<Scene | null>;
}

export const SceneInjection = injectionKey<SceneInjection>();

export default function useScenes() {
  const scenes = shallowRef<Scene[]>([]);
  const isScenesLoaded = ref<boolean>(false);
  const route = useRoute();

  fetchScenes()

  async function fetchScenes() {
    return fetch('/data-scenes-suchbuch.json')
      .then((res) => res.json())
      .then(async (response: Scene[]) => {
        scenes.value = response;
        isScenesLoaded.value = true;
      });
  }

  function sceneByRoute(route: string): Scene | null {
    return scenes.value?.find((s) => s.route === route || s.route === `/${route}`) || null;
  }

  const currentScene = computed((): Scene | null => {
    if (route.params.id) {
      return sceneByRoute(route.params.id as string);
    }
    return null;
  });

  provide(SceneInjection, {
    scenes,
    isScenesLoaded,
    currentScene,
  });
}
