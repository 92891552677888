export default function useGoogleAnalytics(id: string) {
  const scriptHead1 = document.createElement('script');
  scriptHead1.async = true;
  scriptHead1.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${id}`);

  const scriptHead2 = document.createElement('script');
  scriptHead2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${id}');
  `;

  const scriptBody = document.createElement('script');
  scriptBody.innerHTML = `
        gtag('event', 'screen_view', {
        'app_name': 'Suchbuch',
        'screen_name': 'Home'
      });
  `;

  document.head.appendChild(scriptHead1);
  document.head.appendChild(scriptHead2);
  document.body.appendChild(scriptBody);
}
