import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import {AuthEntry, checkAuthStatus} from '~/authentication/authentication.js';
import {SceneData} from '~/types/scenes';


let scenes: SceneData[] = [];

async function checkSceneId({params}: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (!scenes.length) {
    const scenesData = await fetch('/scenes.json');
    scenes = await scenesData.json();
  }

  if (typeof params.id === 'string' && scenes.some(({route}: SceneData) => route === `/${params.id}`)) {
    await next();
  } else {
    await next({name: 'Suchbuch-Overview'});
  }
}

async function checkLoggedInSuchbuch(route: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const isAuthenticated = checkAuthStatus(AuthEntry.Suchbuch);
  if (isAuthenticated) {
    await next();
  } else {
    await next({name: 'Suchbuch-Login'});
  }
}

async function checkLoggedInLPK(route: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const isAuthenticated = checkAuthStatus(AuthEntry.LPK);
  if (isAuthenticated) {
    await next();
  } else {
    await next({name: 'LPK-Login'});
  }
}

const routes: Array<RouteRecordRaw> = [
  /* general views */
  {
    path: '/',
    name: 'Entry',
    component: () => import('../shared/views/Entry.vue'),
  },
  {
    // todo: remove after testing
    path: '/test',
    component: () => import('../shared/views/TestSVG.vue'),
  },
  /* paths for suchbuch */
  {
    path: '/login',
    name: 'Suchbuch-Login',
    component: () => import('../suchbuch/views/Login.vue'),
  },
  {
    path: '/uebersicht',
    name: 'Suchbuch-Overview',
    component: () => import('../suchbuch/views/Overview.vue'),
    beforeEnter: [checkLoggedInSuchbuch],
  },
  {
    path: '/:id',
    name: 'Suchbuch-Detail-Scene',
    component: () => import('../suchbuch/views/DetailScene.vue'),
    beforeEnter: [checkSceneId, checkLoggedInSuchbuch],
  },
  /* paths for LPK */
  {
    path: '/lpk/login',
    name: 'LPK-Login',
    component: () => import('../lehrpersonenkommentar/views/Login.vue'),
  },
  {
    path: '/lpk/uebersicht',
    name: 'LPK-Overview',
    component: () => import('../lehrpersonenkommentar/views/Overview.vue'),
    beforeEnter: [checkLoggedInLPK],
  },
  {
    path: '/lpk/kommentare',
    name: 'LPK-OverviewArticles',
    component: () => import('../lehrpersonenkommentar/views/OverviewArticles.vue'),
    beforeEnter: [checkLoggedInLPK],
  },
  {
    path: '/lpk/kommentare/:id',
    name: 'LPK-Detail-Article',
    component: () => import('../lehrpersonenkommentar/views/DetailArticle.vue'),
    beforeEnter: [checkLoggedInLPK],
    meta: {
      scrollToTop: true
    }
  },
  {
    path: '/lpk/zusatzmaterial-wimmelbilder',
    name: 'LPK-OverviewScenes',
    component: () => import('../lehrpersonenkommentar/views/OverviewScenes.vue'),
    beforeEnter: [checkLoggedInLPK],
  },
  {
    path: '/lpk/zusatzmaterial-wimmelbilder/:id',
    name: 'LPK-Detail-Scene',
    component: () => import('../lehrpersonenkommentar/views/DetailScene.vue'),
    beforeEnter: [checkLoggedInLPK],
    meta: {
      scrollToTop: true
    }
  },
  {
    path: '/lpk/zusatzmaterial-wimmelbilder/:id/cover',
    name: 'LPK-Scene-Cover',
    component: () => import('../lehrpersonenkommentar/views/SceneCover.vue'),
    beforeEnter: [checkLoggedInLPK],
  },
  // wildcard
  {
    path: '/lpk/:pathMatch(.*)*',
    component: () => import('../lehrpersonenkommentar/views/Overview.vue'),
    beforeEnter: [checkLoggedInSuchbuch],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../suchbuch/views/DetailScene.vue'),
    beforeEnter: [checkLoggedInSuchbuch],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {el: to.hash}
    } else if (to.meta.scrollToTop) {
      return {top: 0}
    }
  },
});

export default router;
