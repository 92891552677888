import {
   provide, Ref, ref, shallowRef, ShallowRef,
} from 'vue';
import { injectionKey } from '~/utils/inject';
import {LPKScene} from '~/types/lpk';

interface LPKScenesInjection {
  isLoaded: Ref<boolean>;
  scenes: ShallowRef<LPKScene[]>;
  findSceneByRoute: (route: string) => LPKScene|null;
}

export const LPKScenesInjectionKey = injectionKey<LPKScenesInjection>();
export default function useScenes() {
  const scenes = shallowRef<LPKScene[]>([]);
  const isLoaded = ref<boolean>(false);

  fetchScenes()

  async function fetchScenes() {
    return fetch('/data-scenes-lpk.json')
      .then((res) => res.json())
      .then(async (response: LPKScene[]) => {
        scenes.value = response;
        isLoaded.value = true;
      });
  }

  function findSceneByRoute(r: string){
    if(!scenes.value || scenes.value.length <= 0){
      return false
    }
    return scenes.value.find(a => a.route === r) ?? null
  }

  provide(LPKScenesInjectionKey, {
    scenes,
    isLoaded,
    findSceneByRoute
  });
}
