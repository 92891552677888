<template>
  <div class="exercise noise-exercise">
    <PicturePuzzle
      :scene-id="sceneId"
      :is-noise="true"
      @clicked-canvas="checkSolution"
    />
  </div>
</template>

<script setup lang="ts">

import { watch } from 'vue';
import type { TaskPuzzle } from '~/types/game';
import PicturePuzzle from '~/components/exercises/components/PicturePuzzle.vue';
import { injectStrict } from '~/utils/inject';
import { AudioStreamInjection } from '~/data/useAudio';

const props = defineProps<{
  task: TaskPuzzle;
  sceneId: string;
}>();

const emit = defineEmits<{(e: 'input', isRight: boolean): void }>();

const { playAudioSrc, stopAudioQueue } = injectStrict(AudioStreamInjection);

playAudioSrc(`scenes/${props.sceneId}/audio/${props.task.statement.audio}`);

watch(() => props.task.statement, (newValue) => {
  if (newValue.audio) {
    playAudioSrc(`scenes/${props.sceneId}/audio/${newValue.audio}`, { immediate: true });
  }
});

function checkSolution(layerName: string, resolve: (answer: string, color: string) => void) {
  const isRight = props.task.solutions.includes(layerName);
  emit('input', isRight);
  resolve(isRight ? props.task.statement.key : '', isRight ? 'positive' : 'negative');
}

</script>

<style scoped>

</style>
