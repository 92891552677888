<template>
  <div class="exercise explore-exercise">
    <PicturePuzzle
      :scene-id="sceneId"
      @clicked-canvas="checkSolution"
    />
  </div>
</template>

<script setup lang="ts">
import type { TaskPuzzle } from '~/types/game';
import PicturePuzzle from '~/components/exercises/components/PicturePuzzle.vue';
import { injectStrict } from '~/utils/inject';
import { AudioStreamInjection } from '~/data/useAudio';

const props = defineProps<{
  tasks: TaskPuzzle[];
  sceneId: string;
}>();

const { playAudioSrc, stopAudioQueue } = injectStrict(AudioStreamInjection);

const emit = defineEmits<{(e: 'input', isRight: boolean): void }>();

function checkSolution(layerName: string, resolve: (answer: string, color: string) => void) {
  const item = props.tasks.find((i) => i.solutions.includes(layerName));
  emit('input', false);
  if (item) {
    playAudioSrc(`scenes/${props.sceneId}/audio/${item.statement.audio}`, { immediate: true });
    resolve(item.statement.key, 'neutral');
  }
}
</script>

<style scoped>

</style>
