import './assets/styles/styles.css';
import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import messagesDe from '~/locales/de';
import router from './router';
import App from './App.vue';
import useAppHeight from '~/data/useAppHeight';
import useGoogleAnalytics from '~/data/useGoogleAnalytics';
import useServiceWorker from '~/data/useServiceWorker';
import {createHead} from '@vueuse/head'

const app = createApp(App);
const head = createHead()
const i18n = createI18n({
  legacy: false,
  locale: 'de',
  runtimeOnly: false,
  fallbackLocale: 'de',
  allowComposition: true, // you need to specify that!
  messages: {
    de: messagesDe,
  },
});


app.use(head)
app.use(router);
app.use(i18n);
app.mount('#app');

useServiceWorker('/custom-sw.js');
useAppHeight();
useGoogleAnalytics(import.meta.env.VITE_GOOGLE_PROPERTY_ID);

if (import.meta.env.VITE_APP_ENV === 'prod') {
  initSentry();
}

function initSentry() {
  Sentry.init({
    app,
    dsn: 'https://2f36c8a91a54433b9bd6b089d5a03319@o307906.ingest.sentry.io/4504842129244160',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'suchbuch.ch', /^\//],
      }),
    ],
    environment: import.meta.env.VITE_APP_ENV,
    logErrors: true,
    tracesSampleRate: 0.5,
  });
}
